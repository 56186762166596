.gsap-hidden {
  opacity: 0;
}

.gsap-reveal {
  transform: translateY(100%);
}

.gsap-reveal-reverse {
  transform: translateY(-100%);
}

.hide-overflow {
  overflow: hidden;
}

#background {
  .badge-group .gsap-hidden {
    opacity: 1;
  }
}
