.preloader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 99999;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: $transition;
  position: fixed;
  box-sizing: border-box;

  @media only screen and (max-width: $tablet-small) {
    padding: 5%;
  }
  @media only screen and (max-width: $mobile-portrait-max) {
    padding: 10%;
  }

  .preloader-content {
    h1 {
      font-family: "Gilroy";
      font-weight: 800;
      font-size: 6rem;
      line-height: 7rem;
      margin-bottom: 0rem;
      color: $red;

      @media only screen and (max-width: $tablet-small) {
        font-size: 4.8rem;
        line-height: 5rem;
      }

      @media only screen and (max-width: $mobile-landscape-max) {
        font-size: 3.5rem;
        line-height: 4rem;
      }

      @media only screen and (max-width: $mobile-portrait-max) {
        font-size: 3rem;
        line-height: 5rem;
      }

      span h1 {
        @media only screen and (max-width: $mobile-portrait-max) {
          line-height: 3.3rem;
        }
      }

      &.preloader-text-lite {
        font-weight: 500;
        color: $grey !important;
      }
    }
  }

  .preloader-pulse {
    animation: pulse 0.8s ease-in-out 0.4s;
    -webkit-animation: pulse 0.4s ease-in-out 0.4s;
    -moz-animation: pulse 0.4s ease-in-out 0.4s;
    -o-animation: pulse 0.4s ease-in-out 0.4s;
    -ms-animation: pulse 0.4s ease-in-out 0.4s;
    animation-iteration-count: infinite;
    color: $grey !important;
    font-size: 10rem !important;
    line-height: 10rem !important;
  }

  div {
    overflow: hidden;
  }
}
