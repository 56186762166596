.fp-viewing-contact section.fixed {
  background: $dark-blue;
}
#contact {
  .layout-wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bg-text {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 30rem;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    color: #ffffff00;
    opacity: 0.1;
    -webkit-text-stroke: white;
    -webkit-text-stroke-width: 0.2rem;
    z-index: 0;

    @media only screen and (max-width: $desktop-large) {
      font-size: 20rem;
    }

    @media only screen and (max-width: $tablet-small) {
      font-size: 10rem;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      line-height: 13rem;
      font-size: 15rem;
    }
  }

  .contact-text {
    z-index: 1;
    h1 {
      color: $red;
      font-weight: 600;
      text-align: center;
      color: white;
      line-height: 8rem;

      @media only screen and (max-width: $tablet-small) {
        line-height: 4rem;
      }

      @media only screen and (max-width: $mobile-landscape-max) {
        font-size: 4rem;
        line-height: 5rem;
      }

      &.secondary {
        color: $red;
      }
    }
  }

  .contact-links {
    margin-top: 3rem;
    a {
      color: white;
      fill: white;
      font-size: 2.2rem;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .back-to-top {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);

    //mobile//
    @media only screen and (max-width: $mobile-landscape-max) {
      bottom: 50px;
    }
  }

  .link {
    @media only screen and (max-width: $mobile-portrait-min) {
      font-size: 1.5rem;
      line-height: 3rem;
    }
  }
}

.text-top {
  position: absolute;
  color: #292929f7;
  text-transform: uppercase;
  font-size: 32rem;
  //width: 50%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  line-height: 15rem;
  font-weight: 700;

  @media only screen and (max-width: $desktop-large) {
    font-size: 23rem;
  }

  @media only screen and (max-width: $desktop) {
    font-size: 19rem;
  }

  //mobile//
  @media only screen and (max-width: $mobile-landscape-max) {
    font-size: 8rem;
    line-height: 6rem;
  }
}

.scroll-back-up {
  margin-top: 50px;
  z-index: 9;
  img {
    width: 35px;
  }
}
