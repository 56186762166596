.sharer {
  margin-left: 15px;
  display: flex;
  transition: $transition;

  a {
    transition: 0.2s;
    color: $dark-blue;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 20px;
    border: 1px solid $dark-blue;
    padding: 9px;
    border-radius: 20px;
    white-space: nowrap;
    transition: $transition;

    @media only screen and (max-width: $mobile-landscape-max) {
      margin-right: 0px;
      font-size: 1.4rem;
      border-width: 2px;
      font-weight: 600;
    }

    svg {
      font-size: 1rem;
    }

    &.red {
      color: $red;
    }

    &:hover {
      background: $red;
      color: white;
      border: 2px solid $red;
    }
  }
}

.trigger-sharer {
  &.active {
    color: $red;
  }
}

.sharer-wrapper {
  padding: $frame-size-large;
  position: fixed;
  z-index: 0;
  height: 100%;
  width: 50%;
  top: 0;
  right: -50%;
  box-sizing: border-box;
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-flex;
  flex-direction: row-reverse;
  font-family: "LexendDeca";
  background: white;

  @media only screen and (max-width: $desktop-large) {
    padding: $frame-size-medium;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    width: 100%;
    right: -100%;
  }

  &.active {
    right: 0;
  }
}

.sharer-description {
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  padding: 50px 140px;
  text-align: center;
  position: relative;

  @media only screen and (max-width: $desktop-large) {
    padding: 50px 80px;
  }

  @media only screen and (max-width: $tablet-large) {
    padding-left: 0px;
    padding-right: 10px;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    padding-top: 0px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: bold;
    line-height: 6rem;
    margin-bottom: 0px;
    color: $dark-blue;
    text-align: left;
    margin-top: 20px;

    @media only screen and (max-width: $desktop-large) {
      font-size: 3rem;
      line-height: 4rem;
    }

    @media only screen and (max-width: $tablet-small) {
      font-size: 2.7rem;
    }

    @media only screen and (max-width: $mobile-portrait-max) {
      font-size: 2rem;
      line-height: 2rem;
    }

    &.secondary {
      color: #9fa2a4;
      //font-weight: 100;
      margin-top: 0px;
    }
  }

  #close-sharer {
    cursor: pointer;
    font-size: 35px;
    color: $red;
    transition: 0.3s;
    text-align: left;
    &:hover {
      color: $dark-blue;
    }
  }

  p {
    text-align: left;
  }
}

.sharer-social-media {
  width: 80%;
  margin-top: 80px;

  .contact-links {
    text-align: left;
    svg {
      width: 40px !important;
      height: 40px !important;
      fill: $dark-blue;
      color: $dark-blue;
    }
  }
}

.shift-right {
  right: calc(25% - #{$frame-size-large});
}

.minimise-header {
  width: 50%;
  padding-right: 0px;

  @media only screen and (max-width: $mobile-landscape-max) {
    width: 100%;
  }
}
