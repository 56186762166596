.fp-viewing-outsystems section.fixed {
  background: $dark-blue;
}
#outsystems {
  .content {
    color: white;
    font-family: "Gilroy";
    font-weight: 700;
    letter-spacing: 0.02rem;
    font-size: 3rem;
    text-align: end;

    @media only screen and (max-width: $desktop-large) {
      width: 50%;
    }
    @media only screen and (max-width: $tablet-large) {
      text-align: left;
    }
    @media only screen and (max-width: $tablet-small) {
      width: 65%;
    }
    @media only screen and (max-width: $mobile-landscape-max) {
      width: 100%;
      font-size: 2rem;
      line-height: 2.5rem;
    }

    @media only screen and (max-width: $mobile-portrait-min) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}
.outsystems-logo {
  width: 500px;

  @media only screen and (max-width: $desktop) {
    width: 370px;
  }

  @media only screen and (max-width: $tablet-small) {
    width: 340px;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    width: 230px;
    margin-bottom: 15px;
  }
}

.seperator-small {
  border-bottom: 2px solid white;
  //margin: 30px 96% 15px 0;
  margin: 30px 48%;

  &.m-50 {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  @media only screen and (min-width: $desktop) {
    &.d-hidden {
      display: none;
    }
  }

  @media only screen and (max-width: $desktop) {
    &.t-hidden {
      display: none;
    }
  }
}

.tag {
  padding: 15px 25px;
  font-size: 1.2rem;
  margin: 0px 25px 25px 0px;
  width: 150px;
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  text-transform: uppercase;
  //border: 2px solid;

  @media only screen and (max-width: $desktop) {
    width: 140px;
  }

  @media only screen and (max-width: $tablet-small) {
    padding: 10px 20px;
    margin: 0px 20px 20px 0px;
    width: 100px;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    padding: 5px 15px;
    width: 130px;
    margin: 10px 0;
  }

  .tag-text {
    margin-left: 15px;
  }

  img {
    width: 25px;
    margin-right: 10px;
  }
}

.tag-wrapper {
  margin-top: 40px;
}

.reach-out {
  margin-top: 80px;
  text-align: center;

  a {
    font-size: 2.5em;
  }
}

.os-logo-outer-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  overflow: hidden;
}

.os-logo-inner-circle {
  width: 35%;
  height: 35%;
  border-radius: 50%;
  border: 2px solid white;
}

.os-banner-wrapper {
  width: 86vh;
  height: 86vh;
  position: absolute;
  bottom: 50%;
  opacity: 0.3;
  left: 50%;
  transform: translate(-50%, 50%);
}
.os-banner-wrapper-2 {
  width: 150vh;
  height: 150vh;
  position: absolute;
  bottom: 50%;
  opacity: 0.3;
  left: 50%;
  transform: translate(-50%, 50%);
}

.bg-phrase-wrapper {
  font-size: 10em;
  left: 0px;
  bottom: 0px;
  position: absolute;
  color: #2c2c2c;
  font-weight: 700;
  line-height: 0.95em;
  height: 100%;
  width: 100%;
  font-family: "Gilroy";
  text-transform: uppercase;

  @media only screen and (max-width: $tablet-large) {
    font-size: 8em;
    opacity: 0.5;
  }
  @media only screen and (max-width: $mobile-landscape-max) {
    font-size: 4em;
  }

  .bg-phrase {
    position: absolute;
    top: 28%;
    right: 20%;
    color: #ffffff00;
    //opacity: 0.08;
    -webkit-text-stroke: white;
    -webkit-text-stroke-width: 0.15rem;
    width: fit-content;
    &:nth-child(2) {
      right: 60%;
      top: 45%;
    }
    &:nth-child(3) {
      right: 40%;
      top: 80%;
    }
    &:nth-child(4) {
      right: 10%;
      top: 63%;
    }
    &:nth-child(5) {
      right: 3%;
      top: 10%;
    }
    &:nth-child(6) {
      right: 50%;
      top: 13%;
    }
  }

  &.right {
    color: #ffffff;
    text-align: right;
    z-index: 9;

    @media only screen and (max-width: $tablet-large) {
      bottom: 35%;
    }
  }
}
