.outsystems-badge-wrapper {
  display: flex;

  &.block-view {
    display: block;

    @media only screen and (max-width: $mobile-portrait-min) {
      display: none;
    }

    .badge-item {
      width: 20%;
      @media only screen and (max-width: $tablet-large) {
        width: 40%;
      }
    }
  }

  @media only screen and (max-width: $tablet-large) {
    flex-flow: wrap;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    top: auto;
    justify-content: space-around;
    margin-top: 20px;

    &.mobile-layout {
      .badge-group {
        justify-content: left !important;
        flex-flow: row;
        width: max-content;
        display: inline-flex;
      }
      .badge-name {
        display: none !important;
      }
      .badge-item {
        width: max-content !important;
        margin-right: 10px !important;
        margin-left: 0px !important;
      }
    }

    @media only screen and (max-height: $mobile-height-small) {
      bottom: 300px;
    }
  }

  @media only screen and (max-width: $mobile-portrait-max) {
    bottom: 280px;

    @media only screen and (max-height: $mobile-height-small) {
      bottom: 260px;
    }
  }

  .badge-group {
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-flow: wrap;

    @media only screen and (max-width: $tablet-large) {
      //justify-content: center;
    }
  }
}

.badge-item {
  //transition: 0.8s;
  cursor: pointer;
  margin-right: 25px;
  text-align: left;
  margin-bottom: 20px;

  @media only screen and (max-width: $mobile-landscape-max) {
    text-align: center;
    display: block;
  }
  @media only screen and (max-width: $mobile-portrait-max) {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &:last-child {
    //margin-top: 30px;
    margin-right: 0px;

    @media only screen and (max-width: $mobile-landscape-max) {
      margin-top: 0px;
      margin-left: 30px;
    }
    @media only screen and (max-width: $mobile-portrait-max) {
      margin-left: 10px;
    }
  }

  .badge-name {
    transition: 0.8s;
    z-index: 2;
    position: relative;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    //text-shadow: 5px 4px 0px #1e1e1e8c;
    font-family: "Gilroy";
    font-size: 1.5rem;
    white-space: nowrap;

    &.toggle {
      width: 0px;
      overflow: hidden;
      @media only screen and (max-width: $tablet-large) {
        width: auto;
      }
    }

    @media only screen and (max-width: $desktop-large) {
      font-size: 1.2rem;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      font-weight: 600;
    }

    @media only screen and (max-width: $mobile-portrait-max) {
      font-size: 1.4rem;
    }
    @media only screen and (max-width: $mobile-portrait-min) {
      font-size: 1rem;
    }

    .badge-os-logo {
      display: block;
      margin-bottom: -5px;
      img {
        width: 100px;
        @media only screen and (max-width: $desktop-large) {
          width: 80px;
        }

        @media only screen and (max-width: $mobile-landscape-max) {
          width: 60px;
        }
      }
    }
  }

  &.dark {
    .badge-name {
      color: $dark-blue;
      font-weight: 700;
    }
  }
  &:hover {
    .badge-name.toggle {
      width: 100%;
      overflow: visible;
    }
  }

  .badge-icon {
    width: 45px;
    margin-right: 15px;
    //-webkit-filter: drop-shadow(12px 12px 25px rgba(0, 0, 0, 0.5));

    @media only screen and (max-width: $desktop-large) {
      width: 40px;
    }

    @media only screen and (max-width: $mobile-height-large) {
      margin-right: 5px;
    }

    @media only screen and (max-width: $mobile-portrait-max) {
      width: 30px;
    }
  }
}
