#background {
  @media only screen and (max-width: $mobile-landscape-max) {
    background: $off-white;

    .layout-background {
      opacity: 1;
      background: transparent;
    }
  }
}

.intro {
  text-align: center;
  .heading {
    margin-bottom: 40px;

    h2 {
      margin: 0;
      padding: 0;
    }
  }
}

.about {
  font-size: 4rem;
  width: 100%;
  text-align: center;
  margin-bottom: 4rem;
}

.background {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.1;
  left: 50%;
  width: 80%;

  img {
    width: 100%;
  }

  &.small {
    width: 60%;
  }
}

.background-wrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  padding: $frame-size-large 8%;
  font-family: "LexendDeca";

  @media only screen and (max-width: $desktop-extra-large) {
    padding: $frame-size-medium 5%;
  }

  @media only screen and (max-width: $tablet-large) {
    align-items: center;
  }

  /*mobile*/
  @media only screen and (max-width: $mobile-landscape-max) {
    width: 100%;
    padding-top: 40px;
    line-height: 20px;
  }

  h1 {
    @media only screen and (max-width: $tablet-large) {
      width: 65%;
      font-size: 7rem;
      line-height: 4rem;
      margin-top: 80px;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      text-align: -webkit-left;
      width: inherit;
      font-size: 8rem;
      line-height: 4rem;
    }

    @media only screen and (max-width: $mobile-portrait-max) {
      margin-top: 35px;
      font-size: 5.6rem;
      line-height: 3rem;
    }

    @media only screen and (max-width: $mobile-portrait-min) {
      margin-top: 30px;
      font-size: 4.5rem;
      line-height: 5rem;
    }

    div {
      line-height: initial;
      overflow: hidden;
      margin-bottom: -1.5rem;

      @media only screen and (max-width: $mobile-landscape-max) {
        margin-bottom: -1rem;
      }
    }
  }

  .content {
    width: 40%;
    font-size: 2rem;
    margin-bottom: 5rem;
    text-align: left;
    font-family: "Gilroy";
    font-weight: 700;
    color: $dark-blue;

    @media only screen and (max-width: $desktop-large) {
      font-size: 1.8rem;
    }

    @media only screen and (max-width: $tablet-large) {
      width: 65%;
    }

    &.wide {
      width: 100%;
      @media only screen and (max-width: $tablet-large) {
        width: 65%;
      }
      @media only screen and (max-width: $mobile-landscape-max) {
        width: 100%;
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      width: 100%;
      font-size: 2rem;
      line-height: 3rem;
      text-align: -webkit-left;
      color: $dark-blue;
      margin-bottom: 1.8rem;
    }

    @media only screen and (max-width: $mobile-portrait-min) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}
.skills {
  text-align: center;
  margin-top: 50px;

  @media only screen and (max-width: $tablet-large) {
    margin-top: 55px;
  }

  @media only screen and (max-width: $tablet-small) {
    margin-top: 40px;
  }

  /*mobile*/
  @media only screen and (max-width: $mobile-landscape-max) {
    margin: 30px 0px;
  }

  ul {
    list-style: none;
    display: inline;
    margin: 0;
    padding: 0;

    @media only screen and (max-width: $tablet-large) {
      margin-left: 0;
      padding-left: 0;
    }

    li {
      display: inline-block;
      margin: 30px 1%;
      width: 20%;

      @media only screen and (max-width: $desktop) {
        margin: 20px 1%;
        width: 40%;
      }
      @media only screen and (max-width: $mobile-landscape-max) {
        margin: 20px 1%;
      }

      img {
        @media only screen and (max-width: $tablet-small) {
          width: 80px;
          height: 80px;
        }
        @media only screen and (max-width: $mobile-landscape-max) {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}

.background-bg {
  position: absolute;
  height: 80%;
  left: -30%;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;
}
