#articles {
  position: relative;

  @media only screen and (max-width: $mobile-landscape-max) {
    background: $off-white;

    .layout-background {
      opacity: 1;
      background: transparent;
    }
  }

  .content {
    color: $dark-blue;
    font-family: "Gilroy";
    font-weight: 700;
    letter-spacing: 0.02rem;
    font-size: 3rem;
    margin-bottom: 0px;

    //animation setup
    line-height: initial;
    overflow: hidden;
    margin-bottom: -1.5rem;

    @media only screen and (max-width: $desktop) {
      font-size: initial;
      margin-top: 40px;
    }

    @media only screen and (max-width: $tablet-large) {
      text-align: center;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      font-size: 1.5rem;
      width: 80%;
      display: inline-block;
    }

    @media only screen and (max-width: $mobile-portrait-min) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }

  .title {
    font-size: 11em;
    line-height: 9rem;
    font-family: "Gilroy";
    text-transform: uppercase;
    font-weight: 900;
    color: $dark-blue;
    margin-bottom: 25px;

    //animation setup
    line-height: initial;
    overflow: hidden;
    margin-bottom: -1.5rem;

    @media only screen and (max-width: $desktop) {
      font-size: 6em;
      line-height: 5.5rem;
    }
  }
}

.article-section-background {
  position: absolute;
  right: -192px;
  top: -190px;
  height: 180vh;
  width: 50vw;
  background: #464647;
  transform: rotate(45deg);
  display: none;
}

.articles-wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 4%;
  box-sizing: border-box;
  overflow: hidden;

  @media only screen and (max-width: $tablet-large) {
    justify-content: flex-start;
    text-align: center;
    padding: 4%;
    align-content: center;
    padding-top: 360px;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    padding-top: 120px;
  }
}

.article-section-heading {
  width: 100%;
  margin-bottom: 10%;

  @media only screen and (max-width: $tablet-large) {
    margin-bottom: 80px;
  }
}

.article-section-body {
  width: 1000px;
  transition: $transition;

  @media only screen and (max-width: $desktop-extra-large) {
    width: 880px;
  }

  @media only screen and (max-width: $desktop-large) {
    width: 750px;
  }

  @media only screen and (max-width: $tablet-large) {
    width: 720px;
    align-self: center;
  }

  @media only screen and (max-width: $tablet-small) {
    width: 400px;
  }
}

.article-list {
  width: 100%;
  display: inline-block;
}

.article-card {
  height: 400px;
  width: 450px;
  background: white;
  display: inline-block;
  //margin-left: 35px;
  cursor: pointer;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 15px;
  transition: $transition;
  overflow: hidden;
  //opacity: 0;

  &:hover {
    transform: scale(1.05);
  }

  @media only screen and (max-width: $desktop-extra-large) {
    height: 350px;
    width: 400px;
  }

  @media only screen and (max-width: $desktop-large) {
    height: 300px;
    width: 350px;
  }

  @media only screen and (max-width: $tablet-large) {
    height: 280px;
    width: 330px;
    margin-left: 0px;
  }
}

.article-image {
  height: 70%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  position: relative;
}

.article-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    0deg,
    black 0%,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0) 60%,
    black 120%
  );
}

.article-text {
  display: flex;
  color: #0f1a29;
  padding: 25px;
  font-size: 2em;
  text-align: left;
  font-weight: 600;
  font-family: "SourceSansPro";
  position: relative;
  height: 30%;
  box-sizing: border-box;
  align-items: center;

  @media only screen and (max-width: $desktop-extra-large) {
    font-size: 1.8em;
  }

  @media only screen and (max-width: $desktop-large) {
    font-size: 1.5em;
  }

  @media only screen and (max-width: $tablet-large) {
    font-size: 1.35em;
  }
}

.article-hashtag-container {
  position: absolute;
  bottom: 15px;
  left: 25px;
  z-index: 2;
}

.article-hashtag {
  width: fit-content;
  font-size: 1.4em;
  font-weight: 600;
  font-family: "SourceSansPro";
  border-radius: 20px;
  display: inline-block;
  margin-right: 10px;
  color: $red;

  &:nth-child(2n) {
    color: $yellow;
  }

  &:nth-child(3n) {
    color: $grey;
  }

  @media only screen and (max-width: $tablet-large) {
    font-size: 1.2em;
  }
}

.article-source-container {
  z-index: 3;
  position: absolute;
  right: 25px;
  top: 19px;

  img {
    width: 30px;

    @media only screen and (max-width: $tablet-large) {
      width: 20px;
    }
  }
}

.article-year {
  position: absolute;
  position: absolute;
  font-size: 3.8em;
  opacity: 0.1;
  color: #606060;
  right: 25px;
  bottom: 50%;
  transform: translateY(50%);
  font-weight: 700;
  font-family: "Gilroy";

  @media only screen and (max-width: $desktop-large) {
    font-size: 3em;
  }
}

.article-outsystems-container {
  z-index: 3;
  position: absolute;
  left: 25px;
  top: 25px;

  @media only screen and (max-width: $tablet-large) {
    top: 20px;
  }

  img {
    height: 25px;

    @media only screen and (max-width: $tablet-large) {
      height: 20px;
    }
  }
}

.slick-list {
  overflow: visible;
}

.slick-dots {
  bottom: -55px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: left;

  @media only screen and (max-width: $tablet-large) {
    text-align: center;
  }

  li.slick-active button:before {
    color: $dark-blue;
  }

  li button:before {
    font-size: 16px;
  }
}
