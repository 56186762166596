.borders {
  > * {
    position: fixed;
    z-index: 100;
    background: white;
    height: 20px;
    width: 20px;
    pointer-events: none;
    -webkit-transition: -webkit-transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
    -moz-transition: -moz-transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
    transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: translate(0, 0) scale(1);
    -moz-transform: translate(0, 0) scale(1);
    -ms-transform: translate(0, 0) scale(1);
    -o-transform: translate(0, 0) scale(1);
    transform: translate(0, 0) scale(1);
    transition: $transition;

    @media only screen and (max-width: $mobile-landscape-max) {
      background: transparent;
    }
  }

  > *.top {
    top: 0;
    left: 0;
    width: 100%;
    transform: translateY(-60px);
    -webkit-transform-origin: top center;
    -moz-transform-origin: top center;
    -ms-transform-origin: top center;
    -o-transform-origin: top center;
    transform-origin: top center;
    height: $frame-size-large;

    @media only screen and (max-width: $desktop-large) {
      height: $frame-size-medium;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      height: $frame-size-small;
    }
  }

  > *.bottom {
    bottom: 0;
    left: 0;
    width: 100%;
    transform: translateY(60px);
    -webkit-transform-origin: bottom center;
    -moz-transform-origin: bottom center;
    -ms-transform-origin: bottom center;
    -o-transform-origin: bottom center;
    transform-origin: bottom center;
    height: $frame-size-large;

    @media only screen and (max-width: $desktop-large) {
      height: $frame-size-medium;
    }
    @media only screen and (max-width: $mobile-landscape-max) {
      height: $frame-size-small;
    }
  }

  > *.left {
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-60px);
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -ms-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
    width: $frame-size-large;

    @media only screen and (max-width: $desktop-large) {
      width: $frame-size-medium;
    }
    @media only screen and (max-width: $mobile-landscape-max) {
      width: $frame-size-small;
    }
  }

  > *.right {
    top: 0;
    right: 0;
    height: 100%;
    transform: translateX(60px);
    -webkit-transform-origin: right center;
    -moz-transform-origin: right center;
    -ms-transform-origin: right center;
    -o-transform-origin: right center;
    transform-origin: right center;
    width: $frame-size-large;

    @media only screen and (max-width: $desktop-large) {
      width: $frame-size-medium;
    }
    @media only screen and (max-width: $mobile-landscape-max) {
      width: $frame-size-small;
    }
  }
}
