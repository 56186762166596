.sphere-animation {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 35%;
  //height: calc(100% - (#{$frame-size-large} * 2));
  transform: translate(50%, -50%) scale(1) !important;
  z-index: 1;
  transition: 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media only screen and (max-width: $desktop-large) {
    //height: calc(100% - (#{$frame-size-medium} * 2));
  }
  @media only screen and (max-width: $desktop) {
  }
  /*tablet*/
  @media only screen and (max-width: $tablet-large) {
    width: 60%;
  }
  @media only screen and (max-width: $tablet-small) {
  }

  /*mobile*/
  @media only screen and (max-width: $mobile-landscape-max) {
    //transform: translate(50%, -50%) scale(0.4) !important;
    top: 30%;
  }

  @media only screen and (max-width: $mobile-portrait-max) {
    top: 31%;
  }
}

.fp-viewing-background .sphere-animation,
.fp-viewing-articles .sphere-animation {
  right: 25%;
  @media only screen and (max-width: $tablet-large) {
    top: 25%;
    width: 30%;
    right: 50%;
  }
  @media only screen and (max-width: $mobile-landscape-max) {
    top: -50%;
    right: 50%;
  }
}

.fp-viewing-articles .sphere-animation {
  opacity: 0.5;
  @media only screen and (max-width: $tablet-large) {
    opacity: 1;
  }
}

.fp-viewing-outsystems .sphere-animation {
  right: 25%;
  opacity: 0%;

  @media only screen and (max-width: $tablet-large) {
    top: 25%;
    width: 30%;
    right: 50%;
    opacity: 0%;
  }
}

.fp-viewing-portfolio .sphere-animation,
.fp-viewing-sca .sphere-animation,
.fp-viewing-rrba .sphere-animation,
.fp-viewing-contact .sphere-animation {
  opacity: 0%;
  right: 125%;

  @media (max-width: $tablet-large) {
    top: -50%;
    right: 50%;
  }
}

.sphere-shadow {
  background: radial-gradient(39% 50%, #0000004a 0%, #fff0 100%);
  height: 68px;
  width: 100%;
  //position: absolute;
  bottom: 0;
}
.sphere path {
  fill: url(#sphereGradient);
  stroke-width: 1px;
  stroke: rgba(80, 80, 80, 0.35);
  backface-visibility: hidden;
}

@media (min-width: $mobile-landscape-max) {
  .sphere path {
    stroke-width: 0.4px;
  }
}

.sphere-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  background-image: radial-gradient(transparent 37%, #1a1a1a52 98%);
  display: flex;
  justify-content: center;
  align-content: center;
}
