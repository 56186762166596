@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "LexendDeca";
  src: url(/static/media/LexendDeca.d90b3bff.ttf);
  font-display: swap; }

@font-face {
  font-family: "SourceCodePro";
  src: url(/static/media/SourceCodePro-Medium.f621c504.ttf);
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-ExtraLight.23a4c2de.ttf);
  font-weight: 200;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-ExtraLightItalic.542b437a.ttf);
  font-weight: 200;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-Light.81cd217e.ttf);
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-LightItalic.54e87ded.ttf);
  font-weight: 300;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-Regular.c1678b46.ttf);
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-Italic.3d7cb865.ttf);
  font-weight: 400;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-SemiBold.83476a89.ttf);
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-SemiBoldItalic.40000185.ttf);
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-Bold.8669b870.ttf);
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-BoldItalic.38845dae.ttf);
  font-weight: 700;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-Black.9ded577f.ttf);
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "SourceSansPro";
  src: url(/static/media/SourceSansPro-BlackItalic.9912ce73.ttf);
  font-weight: 900;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Light.ef3d6f93.woff);
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Regular.b963b73c.woff);
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Medium.d35dfeb0.woff);
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Bold.9747c221.woff);
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gilroy";
  src: url(/static/media/Gilroy-Heavy.0e2ee559.woff);
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

.strong-font {
  font-weight: 900; }

.red-font {
  color: #ff5851;
  font-family: "LexendDeca"; }

.yellow-font {
  color: #bb860f;
  font-family: "LexendDeca"; }

.turquoise-font {
  color: #0d8976;
  font-family: "LexendDeca"; }

.white-font {
  color: white;
  font-family: "LexendDeca"; }

html {
  scroll-behavior: smooth;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  text-rendering: optimizeLegibility;
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  /*
  @media only screen and (max-width: $tablet-large) {
    font-size: $root-size-tablet-large;
  }
  @media only screen and (max-width: $tablet-small) {
    font-size: $root-size-tablet-small;
  }
  
  @media only screen and (max-width: $mobile-landscape-max) {
    font-size: $root-size-mobile;
  }

  @media only screen and (max-width: $mobile-portrait-max) {
    font-size: $root-size-mobile-small;
  }
*/ }
  html.no-scroll {
    overflow: hidden; }

body {
  font-family: "SourceSansPro";
  height: 100%;
  color: white;
  user-select: none;
  /* supported by Chrome and Opera */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */ }

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* make scrollbar transparent */ }

h1,
h2,
h3,
h4,
h5,
h6 {
  /*reset for mobile browsers */
  font-weight: normal;
  font-family: "Gilroy"; }

h1 {
  text-transform: uppercase;
  font-family: "Gilroy";
  font-size: 10rem;
  font-weight: 900;
  line-height: 9rem;
  margin-bottom: 5rem;
  color: #101c2f;
  margin-top: 0px; }
  @media only screen and (max-width: 1700px) {
    h1 {
      line-height: 5rem;
      margin-bottom: 3rem; } }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 5.5rem;
      line-height: 5rem;
      margin-bottom: 3rem; } }
  h1.white {
    color: white; }

h2 {
  font-size: 4rem; }

h3 {
  font-size: 3rem; }

h4 {
  font-weight: 600; }

h5 {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bolder; }

p {
  font-size: 2rem; }

section {
  background: transparent;
  min-height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  right: 0;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }
  section.infinite {
    padding: 0px;
    /*tablet*/
    /*mobile*/ }
    @media only screen and (max-width: 1024px) {
      section.infinite {
        padding: 7.5rem; } }
    @media only screen and (max-width: 480px) {
      section.infinite {
        padding: 0; } }
  section.fixed {
    position: fixed; }
  section .floating-text {
    position: absolute;
    font-size: 3rem !important;
    color: grey; }
    section .floating-text:nth-child(1) {
      top: 40%;
      right: 50%; }
    section .floating-text:nth-child(2) {
      top: 20%;
      right: 10%; }
    section .floating-text:nth-child(3) {
      top: 60%;
      right: 60%; }
    section .floating-text:nth-child(4) {
      top: 10%;
      right: 90%; }

.container {
  position: relative;
  width: 1440px;
  max-width: calc(100% - 48px);
  margin-left: auto;
  margin-right: auto; }
  .container.wide {
    width: 1840px; }
    @media only screen and (max-width: 1224px) {
      .container.wide {
        width: 75%; } }
  @media only screen and (max-width: 1224px) {
    .container {
      width: 75%; } }

strong {
  font-weight: bold;
  text-shadow: 5px 4px 0px #1b1b1b; }

.link {
  text-align: center;
  color: #ff5851;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  font-size: 2.2rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  z-index: 1;
  cursor: pointer;
  border-bottom: 1px solid;
  border-top: 1px solid;
  padding-bottom: 3px;
  font-weight: 500;
  font-family: "SourceSansPro"; }
  .link:hover {
    color: #1a1a1a;
    background: #ff5851; }
  .link.large-link {
    font-size: 3rem; }

.button,
button {
  font-family: "LexendDeca";
  outline: none;
  border: 2px solid;
  border-color: #ff5851;
  border-radius: 40px;
  background-color: #ff5851;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
  padding: 20px 70px;
  font-size: 1.2rem;
  cursor: pointer;
  -webkit-transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
  transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
  text-decoration: none;
  display: inline-block;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .button.secondary,
  button.secondary {
    background-color: transparent;
    color: #ff5851; }
  @media only screen and (max-width: 1920px) {
    .button,
    button {
      padding: 15px 50px; } }
  @media only screen and (max-width: 1024px) {
    .button,
    button {
      padding: 15px 50px; } }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    .button,
    button {
      padding: 15px 50px; } }
  @media only screen and (max-width: 768px) {
    .button,
    button {
      padding: 12px 40px; } }
  @media only screen and (max-width: 480px) {
    .button,
    button {
      font-size: 1.3rem; } }
  .button:hover,
  button:hover {
    background-color: transparent;
    color: #ff5851; }
    .button:hover.secondary,
    button:hover.secondary {
      background-color: #ff5851;
      color: white; }

.code {
  font-family: SourceCodePro; }

.subtitle {
  font-size: 2rem; }
  @media only screen and (max-width: 480px) {
    .subtitle {
      font-size: 3.5rem;
      line-height: 4rem; } }
  @media only screen and (max-width: 320px) {
    .subtitle {
      font-size: 2rem;
      line-height: 3rem; } }

.white-bg {
  background: white;
  color: #1a1a1a; }

.grey-bg {
  background: #e6e5e5;
  color: #1a1a1a; }
  .grey-bg a.link {
    color: #1a1a1a; }

.white-text {
  color: white; }

.hide {
  display: none; }
  @media only screen and (max-width: 480px) {
    .hide.show-on-mobile {
      display: block; } }

@media only screen and (max-width: 480px) {
  .hide-on-mobile {
    display: none; } }

.center-align {
  text-align: center; }

.bring-front {
  z-index: 1; }

.switch-wrapper {
  position: relative; }

.align-left {
  text-align: left; }

.align-right {
  text-align: right; }

.align-center {
  text-align: center; }

.vertical-align {
  display: flex;
  align-items: center; }

.col-2 {
  display: flex;
  width: 100%;
  margin-bottom: 50px; }
  @media only screen and (max-width: 480px) {
    .col-2 {
      display: block; } }

.col {
  width: 50%;
  display: inline-block; }
  @media only screen and (max-width: 480px) {
    .col {
      width: 100%; }
      .col.align-right {
        text-align: center; }
      .col.align-left {
        text-align: center; } }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-0 {
  margin-top: 0; }

@media only screen and (max-width: 1224px) {
  .t-margin-top-30 {
    margin-top: 30px; }
  .t-margin-top-60 {
    margin-top: 60px; } }

@media only screen and (max-width: 480px) {
  .m-margin-top-0 {
    margin-top: 0px; } }

.margin-bottom-0 {
  margin-bottom: 0; }

.margin-bottom-25 {
  margin-bottom: 25px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

@media only screen and (max-width: 1224px) {
  .t-margin-bottom-0 {
    margin-bottom: 0px; } }

@media only screen and (max-width: 480px) {
  .m-margin-bottom-10 {
    margin-bottom: 10px; } }

.os-logo {
  border: 55vh solid #862e20;
  border-radius: 100vh;
  width: 40vh;
  height: 40vh;
  position: absolute;
  bottom: 50%;
  opacity: 0.8;
  left: 75%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
  background-image: -webkit-radial-gradient(transparent 37%, #1a1a1a 98%);
  background-image: radial-gradient(transparent 37%, #1a1a1a 98%); }

.preloader-wrapper {
  height: 100vh;
  width: 100vw;
  background-color: white;
  z-index: 99999;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: fixed;
  box-sizing: border-box; }
  @media only screen and (max-width: 768px) {
    .preloader-wrapper {
      padding: 5%; } }
  @media only screen and (max-width: 375px) {
    .preloader-wrapper {
      padding: 10%; } }
  .preloader-wrapper .preloader-content h1 {
    font-family: "Gilroy";
    font-weight: 800;
    font-size: 6rem;
    line-height: 7rem;
    margin-bottom: 0rem;
    color: #ff5851; }
    @media only screen and (max-width: 768px) {
      .preloader-wrapper .preloader-content h1 {
        font-size: 4.8rem;
        line-height: 5rem; } }
    @media only screen and (max-width: 480px) {
      .preloader-wrapper .preloader-content h1 {
        font-size: 3.5rem;
        line-height: 4rem; } }
    @media only screen and (max-width: 375px) {
      .preloader-wrapper .preloader-content h1 {
        font-size: 3rem;
        line-height: 5rem; } }
    @media only screen and (max-width: 375px) {
      .preloader-wrapper .preloader-content h1 span h1 {
        line-height: 3.3rem; } }
    .preloader-wrapper .preloader-content h1.preloader-text-lite {
      font-weight: 500;
      color: #606060 !important; }
  .preloader-wrapper .preloader-pulse {
    animation: pulse 0.8s ease-in-out 0.4s;
    -webkit-animation: pulse 0.4s ease-in-out 0.4s;
    -moz-animation: pulse 0.4s ease-in-out 0.4s;
    -o-animation: pulse 0.4s ease-in-out 0.4s;
    -ms-animation: pulse 0.4s ease-in-out 0.4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    color: #606060 !important;
    font-size: 10rem !important;
    line-height: 10rem !important; }
  .preloader-wrapper div {
    overflow: hidden; }

.borders > * {
  position: fixed;
  z-index: 100;
  background: white;
  height: 20px;
  width: 20px;
  pointer-events: none;
  -webkit-transition: -webkit-transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  transition: -webkit-transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  transition: transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms, -webkit-transform 500ms cubic-bezier(0.7, 0, 0.3, 1) 0ms;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-transform: translate(0, 0) scale(1);
  transform: translate(0, 0) scale(1);
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (max-width: 480px) {
    .borders > * {
      background: transparent; } }

.borders > *.top {
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-60px);
          transform: translateY(-60px);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  height: 55px; }
  @media only screen and (max-width: 1700px) {
    .borders > *.top {
      height: 40px; } }
  @media only screen and (max-width: 480px) {
    .borders > *.top {
      height: 20px; } }

.borders > *.bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(60px);
          transform: translateY(60px);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  height: 55px; }
  @media only screen and (max-width: 1700px) {
    .borders > *.bottom {
      height: 40px; } }
  @media only screen and (max-width: 480px) {
    .borders > *.bottom {
      height: 20px; } }

.borders > *.left {
  top: 0;
  left: 0;
  height: 100%;
  -webkit-transform: translateX(-60px);
          transform: translateX(-60px);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  width: 55px; }
  @media only screen and (max-width: 1700px) {
    .borders > *.left {
      width: 40px; } }
  @media only screen and (max-width: 480px) {
    .borders > *.left {
      width: 20px; } }

.borders > *.right {
  top: 0;
  right: 0;
  height: 100%;
  -webkit-transform: translateX(60px);
          transform: translateX(60px);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  width: 55px; }
  @media only screen and (max-width: 1700px) {
    .borders > *.right {
      width: 40px; } }
  @media only screen and (max-width: 480px) {
    .borders > *.right {
      width: 20px; } }

.fullpage-wrapper {
  z-index: 2; }

.layout-wrapper {
  display: flex;
  height: 100%;
  padding: 55px;
  box-sizing: border-box; }
  @media only screen and (max-width: 1700px) {
    .layout-wrapper {
      padding: 40px; } }
  @media only screen and (max-width: 480px) {
    .layout-wrapper {
      padding: 0px; } }

.layout-base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow: hidden; }

.layout-left {
  width: 27%;
  padding-left: 13%;
  height: 75%;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1224px) {
    .layout-left {
      width: 70%;
      padding-left: 0;
      text-align: center; } }
  @media only screen and (max-width: 768px) {
    .layout-left {
      height: 60%;
      margin-top: 40px; } }
  @media only screen and (max-width: 375px) {
    .layout-left {
      margin-top: 17vh; } }

.layout-right {
  width: 63.5%;
  height: 100%;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1224px) {
    .layout-right {
      width: 108%;
      height: 55%; } }
  @media screen and (orientation: landscape) {
    .layout-right {
      width: 70%; } }

.layout-heading {
  font-size: 7rem;
  font-weight: 600; }

.layout-subheading {
  font-size: 2.5rem;
  font-weight: 100; }

.layout-floating-card {
  width: 100%;
  height: 100%;
  position: relative;
  text-align: right; }
  @media only screen and (max-width: 480px) {
    .layout-floating-card {
      margin-top: 10vh; } }
  @media only screen and (max-width: 375px) {
    .layout-floating-card {
      margin-top: 20vh; } }
  .layout-floating-card .slide-1 {
    height: 100%;
    background: #ff5851;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000; }
  .layout-floating-card .slide-effect {
    animation: card-grow 0.8s ease-in-out 0.4s;
    -webkit-animation: card-grow 0.8s ease-in-out 0.4s;
    -moz-animation: card-grow 0.8s ease-in-out 0.4s;
    -o-animation: card-grow 0.8s ease-in-out 0.4s;
    -ms-animation: card-grow 0.8s ease-in-out 0.4s; }

.layout-background {
  height: 100%;
  width: 100%;
  background: #0e18217a;
  position: absolute;
  overflow: hidden;
  opacity: 0.6;
  top: 0;
  left: 0;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }

.project-separator {
  height: 7px;
  background: #b72609;
  width: 60px;
  margin-bottom: 40px;
  margin-top: 20px;
  border-radius: 20px; }

@-webkit-keyframes card-grow {
  from {
    width: 0%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    width: 100%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

@keyframes card-grow {
  from {
    width: 0%;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }
  to {
    width: 100%;
    -webkit-transform: translateX(100%);
            transform: translateX(100%); } }

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  box-sizing: border-box;
  align-items: center;
  padding: 0px 55px;
  height: 55px;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (max-width: 1700px) {
    header {
      height: 40px;
      padding: 0px 40px; } }
  @media only screen and (max-width: 480px) {
    header {
      padding: 0px 20px; } }
  header .name {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: #101c2f;
    font-family: "LexendDeca";
    -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s; }
    @media only screen and (max-width: 1700px) {
      header .name {
        font-size: 1rem; } }
    @media only screen and (max-width: 1024px) {
      header .name {
        width: 35%; } }
    @media only screen and (max-width: 480px) {
      header .name {
        font-size: 1.4rem;
        width: 50%; } }
  @media only screen and (max-width: 480px) {
    header .contact-links {
      display: none; } }

@media only screen and (max-width: 480px) {
  .fp-viewing-background header .name {
    color: #ff5851; }
  .fp-viewing-background .sharer a {
    border-color: #ff5851;
    color: #ff5851; }
  .fp-viewing-outsystems .name,
  .fp-viewing-contact .name {
    color: white; }
  .fp-viewing-outsystems .sharer a,
  .fp-viewing-contact .sharer a {
    border-color: white;
    color: white; } }

.header-options {
  display: flex;
  height: 22px;
  overflow: hidden; }

.contact-links {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 110px; }
  @media only screen and (max-height: 670px) {
    .contact-links {
      bottom: 80px; } }
  .contact-links ul {
    list-style: none;
    margin: 0;
    display: inline;
    padding: 0; }
    .contact-links ul li {
      margin: 0 15px;
      display: inline; }
      .contact-links ul li:first-child {
        margin-left: 0px; }
      .contact-links ul li:last-child {
        margin-right: 0px; }
      .contact-links ul li a {
        -webkit-transition: 0.2s;
        transition: 0.2s;
        color: #101c2f;
        font-size: 1.2rem;
        display: inline-flex; }
        .contact-links ul li a:hover, .contact-links ul li a.active {
          color: #ff5851; }
        .contact-links ul li a svg {
          -webkit-transition: 0.2s;
          transition: 0.2s;
          width: 12px;
          height: 20px; }
          .contact-links ul li a svg:hover {
            fill: #ff5851;
            color: #ff5851; }
  .contact-links img {
    max-width: 1.2rem;
    max-height: 1.2rem; }

nav {
  position: fixed;
  left: 20px;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1000; }
  @media only screen and (max-width: 1700px) {
    nav {
      left: 10px; } }
  @media only screen and (max-width: 480px) {
    nav {
      display: none; } }
  nav ul {
    list-style: none;
    margin: 0;
    padding: 0px; }
    nav ul li {
      margin: 12px 0px;
      opacity: 0.4;
      -webkit-transition: 0.2s;
      transition: 0.2s;
      cursor: pointer;
      background: #101c2f;
      height: 4px;
      width: 20px;
      color: #0e1821;
      position: relative;
      text-align: center;
      font-size: 2rem;
      overflow: hidden; }
      @media only screen and (max-width: 1700px) {
        nav ul li {
          font-size: 1.8rem; } }
      nav ul li:hover {
        height: 6px;
        opacity: 1; }
      nav ul li:first-child {
        margin-top: 0px; }
      nav ul li:last-child {
        margin-bottom: 0px; }
      nav ul li.active {
        opacity: 1;
        height: 5px;
        overflow: visible;
        margin-bottom: 30px; }
      nav ul li span {
        font-family: "Gilroy";
        font-weight: 700;
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
        margin-top: 6px; }
      nav ul li a {
        overflow: hidden;
        color: white; }

form {
  text-align: right; }
  form input,
  form textarea {
    font-family: "LexendDeca";
    border: 0;
    outline: none;
    border-bottom: 2px solid #9fa2a4;
    width: 100%;
    height: 50px;
    margin-bottom: 40px;
    font-size: 1.5rem;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    resize: none;
    text-transform: uppercase; }
    @media only screen and (max-width: 1024px) {
      form input,
      form textarea {
        margin-bottom: 20px; } }
    @media only screen and (max-width: 375px) {
      form input,
      form textarea {
        font-size: 1.2rem;
        line-height: 1.2rem; } }
    form input:focus,
    form textarea:focus {
      border-color: #ff5851; }
      form input:focus::-webkit-input-placeholder,
      form textarea:focus::-webkit-input-placeholder {
        text-transform: uppercase;
        opacity: 0; }
      form input:focus::-moz-placeholder,
      form textarea:focus::-moz-placeholder {
        text-transform: uppercase;
        opacity: 0; }
      form input:focus:-ms-input-placeholder,
      form textarea:focus:-ms-input-placeholder {
        text-transform: uppercase;
        opacity: 0; }
      form input:focus::-ms-input-placeholder,
      form textarea:focus::-ms-input-placeholder {
        text-transform: uppercase;
        opacity: 0; }
      form input:focus::placeholder,
      form textarea:focus::placeholder {
        text-transform: uppercase;
        opacity: 0; }
  form textarea {
    height: auto; }
    form textarea::-webkit-input-placeholder {
      line-height: 100px; }
    form textarea::-moz-placeholder {
      line-height: 100px; }
    form textarea:-ms-input-placeholder {
      line-height: 100px; }
    form textarea::-ms-input-placeholder {
      line-height: 100px; }
    form textarea::placeholder {
      line-height: 100px; }
      @media only screen and (max-width: 480px) {
        form textarea::-webkit-input-placeholder {
          line-height: 60px; }
        form textarea::-moz-placeholder {
          line-height: 60px; }
        form textarea:-ms-input-placeholder {
          line-height: 60px; }
        form textarea::-ms-input-placeholder {
          line-height: 60px; }
        form textarea::placeholder {
          line-height: 60px; } }
  form .error-message {
    color: #ff5851;
    width: 100%;
    text-align: left;
    margin-top: -20px;
    font-family: "Gilroy"; }
  form .success-message {
    width: 80%;
    text-align: left;
    color: #ff5851 !important;
    font-weight: 900 !important;
    font-family: "Gilroy"; }

.project-form {
  width: 100%; }
  .project-form .w100-right {
    margin-top: 60px;
    text-align: right; }
    @media only screen and (max-width: 1920px) {
      .project-form .w100-right {
        margin-top: 20px; } }
  .project-form .form-line {
    border-bottom: 3px solid #bb860f;
    width: 10%;
    display: inline-block; }
  .project-form form {
    width: 100%;
    margin-top: 30px; }
    @media only screen and (max-width: 1920px) {
      .project-form form {
        margin-top: 10px; } }

.sphere-animation {
  position: absolute;
  top: 50%;
  right: 50%;
  width: 35%;
  -webkit-transform: translate(50%, -50%) scale(1) !important;
          transform: translate(50%, -50%) scale(1) !important;
  z-index: 1;
  -webkit-transition: 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
  /*tablet*/
  /*mobile*/ }
  @media only screen and (max-width: 1024px) {
    .sphere-animation {
      width: 60%; } }
  @media only screen and (max-width: 480px) {
    .sphere-animation {
      top: 30%; } }
  @media only screen and (max-width: 375px) {
    .sphere-animation {
      top: 31%; } }

.fp-viewing-background .sphere-animation,
.fp-viewing-articles .sphere-animation {
  right: 25%; }
  @media only screen and (max-width: 1024px) {
    .fp-viewing-background .sphere-animation,
    .fp-viewing-articles .sphere-animation {
      top: 25%;
      width: 30%;
      right: 50%; } }
  @media only screen and (max-width: 480px) {
    .fp-viewing-background .sphere-animation,
    .fp-viewing-articles .sphere-animation {
      top: -50%;
      right: 50%; } }

.fp-viewing-articles .sphere-animation {
  opacity: 0.5; }
  @media only screen and (max-width: 1024px) {
    .fp-viewing-articles .sphere-animation {
      opacity: 1; } }

.fp-viewing-outsystems .sphere-animation {
  right: 25%;
  opacity: 0%; }
  @media only screen and (max-width: 1024px) {
    .fp-viewing-outsystems .sphere-animation {
      top: 25%;
      width: 30%;
      right: 50%;
      opacity: 0%; } }

.fp-viewing-portfolio .sphere-animation,
.fp-viewing-sca .sphere-animation,
.fp-viewing-rrba .sphere-animation,
.fp-viewing-contact .sphere-animation {
  opacity: 0%;
  right: 125%; }
  @media (max-width: 1024px) {
    .fp-viewing-portfolio .sphere-animation,
    .fp-viewing-sca .sphere-animation,
    .fp-viewing-rrba .sphere-animation,
    .fp-viewing-contact .sphere-animation {
      top: -50%;
      right: 50%; } }

.sphere-shadow {
  background: -webkit-radial-gradient(39% 50%, #0000004a 0%, #fff0 100%);
  background: radial-gradient(39% 50%, #0000004a 0%, #fff0 100%);
  height: 68px;
  width: 100%;
  bottom: 0; }

.sphere path {
  fill: url(#sphereGradient);
  stroke-width: 1px;
  stroke: rgba(80, 80, 80, 0.35);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden; }

@media (min-width: 480px) {
  .sphere path {
    stroke-width: 0.4px; } }

.sphere-bg {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  background-image: -webkit-radial-gradient(transparent 37%, #1a1a1a52 98%);
  background-image: radial-gradient(transparent 37%, #1a1a1a52 98%);
  display: flex;
  justify-content: center;
  align-content: center; }

.sharer {
  margin-left: 15px;
  display: flex;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .sharer a {
    -webkit-transition: 0.2s;
    transition: 0.2s;
    color: #101c2f;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 20px;
    border: 1px solid #101c2f;
    padding: 9px;
    border-radius: 20px;
    white-space: nowrap;
    -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }
    @media only screen and (max-width: 480px) {
      .sharer a {
        margin-right: 0px;
        font-size: 1.4rem;
        border-width: 2px;
        font-weight: 600; } }
    .sharer a svg {
      font-size: 1rem; }
    .sharer a.red {
      color: #ff5851; }
    .sharer a:hover {
      background: #ff5851;
      color: white;
      border: 2px solid #ff5851; }

.trigger-sharer.active {
  color: #ff5851; }

.sharer-wrapper {
  padding: 55px;
  position: fixed;
  z-index: 0;
  height: 100%;
  width: 50%;
  top: 0;
  right: -50%;
  box-sizing: border-box;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  display: inline-flex;
  flex-direction: row-reverse;
  font-family: "LexendDeca";
  background: white; }
  @media only screen and (max-width: 1700px) {
    .sharer-wrapper {
      padding: 40px; } }
  @media only screen and (max-width: 480px) {
    .sharer-wrapper {
      width: 100%;
      right: -100%; } }
  .sharer-wrapper.active {
    right: 0; }

.sharer-description {
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  padding: 50px 140px;
  text-align: center;
  position: relative; }
  @media only screen and (max-width: 1700px) {
    .sharer-description {
      padding: 50px 80px; } }
  @media only screen and (max-width: 1024px) {
    .sharer-description {
      padding-left: 0px;
      padding-right: 10px; } }
  @media only screen and (max-width: 480px) {
    .sharer-description {
      padding-top: 0px; } }
  .sharer-description h2 {
    text-transform: uppercase;
    font-size: 5rem;
    font-weight: bold;
    line-height: 6rem;
    margin-bottom: 0px;
    color: #101c2f;
    text-align: left;
    margin-top: 20px; }
    @media only screen and (max-width: 1700px) {
      .sharer-description h2 {
        font-size: 3rem;
        line-height: 4rem; } }
    @media only screen and (max-width: 768px) {
      .sharer-description h2 {
        font-size: 2.7rem; } }
    @media only screen and (max-width: 375px) {
      .sharer-description h2 {
        font-size: 2rem;
        line-height: 2rem; } }
    .sharer-description h2.secondary {
      color: #9fa2a4;
      margin-top: 0px; }
  .sharer-description #close-sharer {
    cursor: pointer;
    font-size: 35px;
    color: #ff5851;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-align: left; }
    .sharer-description #close-sharer:hover {
      color: #101c2f; }
  .sharer-description p {
    text-align: left; }

.sharer-social-media {
  width: 80%;
  margin-top: 80px; }
  .sharer-social-media .contact-links {
    text-align: left; }
    .sharer-social-media .contact-links svg {
      width: 40px !important;
      height: 40px !important;
      fill: #101c2f;
      color: #101c2f; }

.shift-right {
  right: calc(25% - 55px); }

.minimise-header {
  width: 50%;
  padding-right: 0px; }
  @media only screen and (max-width: 480px) {
    .minimise-header {
      width: 100%; } }

#banner .name {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 5;
  left: 50%;
  text-align: center;
  /*mobile*/ }
  @media only screen and (max-width: 480px) {
    #banner .name {
      top: 65%;
      display: flex;
      flex-direction: column-reverse; } }
  @media only screen and (max-width: 320px) {
    #banner .name {
      width: 80%; } }
  #banner .name p {
    font-size: 8rem;
    margin: 0;
    line-height: 8.5rem;
    text-shadow: 5px 2px 0px #101c2f;
    font-size: 15rem;
    line-height: 12.5rem;
    font-family: "Bebas Neue";
    font-weight: 200;
    letter-spacing: 0.04em; }
    @media only screen and (max-width: 1700px) {
      #banner .name p {
        font-size: 12rem;
        line-height: 10rem; } }
    @media only screen and (max-width: 480px) {
      #banner .name p {
        font-size: 9rem;
        line-height: 7rem; } }
    @media only screen and (max-width: 320px) {
      #banner .name p {
        font-size: 6rem;
        line-height: 5rem; } }
  @media only screen and (max-width: 480px) {
    #banner .name {
      text-align: center;
      margin-left: auto;
      margin-right: auto; } }
  @media only screen and (max-width: 480px) and (max-height: 670px) {
    #banner .name {
      top: 65%; } }

.banner-awards {
  position: absolute;
  right: 40px;
  top: 100px;
  z-index: 5; }
  .banner-awards li {
    list-style: none;
    margin-bottom: 15px; }
    .banner-awards li img {
      width: 100px; }

.banner-contact-wrapper {
  display: none; }
  @media only screen and (max-width: 480px) {
    .banner-contact-wrapper {
      display: block;
      margin-top: 20px; } }
  .banner-contact-wrapper .contact-links ul li a {
    font-size: 3rem; }
    .banner-contact-wrapper .contact-links ul li a svg {
      height: 25px;
      width: 25px; }

.scroll {
  position: absolute;
  left: 50%;
  z-index: 9999;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer; }
  @media only screen and (max-width: 480px) {
    .scroll {
      display: none; } }
  .scroll.scroll-down {
    bottom: calc(55px/ 2);
    -webkit-transform: translate(-50%, 50%);
            transform: translate(-50%, 50%); }
    @media only screen and (max-width: 1700px) {
      .scroll.scroll-down {
        bottom: calc(40px/ 2); } }
  .scroll.scroll-up {
    top: -10%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    opacity: 0; }
    @media only screen and (max-width: 1700px) {
      .scroll.scroll-up {
        top: -10%; } }
  .scroll.move-left {
    left: 25%; }
  .scroll img {
    width: 30px; }
    @media only screen and (max-width: 1700px) {
      .scroll img {
        width: 22px; } }

.fp-viewing-contact .scroll-down {
  opacity: 0;
  bottom: -10%;
  top: auto; }

.fp-viewing-contact .scroll-up {
  top: calc(55px/ 2);
  opacity: 1; }
  @media only screen and (max-width: 1700px) {
    .fp-viewing-contact .scroll-up {
      top: calc(40px/ 2); } }

.fp-viewing-contact .scroll-text {
  left: -55px; }

.fp-viewing-contact .up {
  display: inline;
  display: initial; }

.fp-viewing-contact .down {
  display: none; }

.typed-cursor {
  display: contents !important; }

.download {
  position: absolute;
  top: 16.5rem;
  right: 16.5rem;
  z-index: 9; }
  @media only screen and (max-width: 480px) {
    .download {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      top: 30px; } }
  .download img {
    width: 6rem; }

@-webkit-keyframes pulse {
  from {
    opacity: 1; }
  50% {
    opacity: 0.6; }
  to {
    opacity: 1; } }

@keyframes pulse {
  from {
    opacity: 1; }
  50% {
    opacity: 0.6; }
  to {
    opacity: 1; } }

@-webkit-keyframes bob {
  0% {
    -webkit-transform: translateY(0.3em);
            transform: translateY(0.3em); }
  50% {
    -webkit-transform: translateY(-0.3em);
            transform: translateY(-0.3em); }
  100% {
    -webkit-transform: translateY(0.3em);
            transform: translateY(0.3em); } }

@keyframes bob {
  0% {
    -webkit-transform: translateY(0.3em);
            transform: translateY(0.3em); }
  50% {
    -webkit-transform: translateY(-0.3em);
            transform: translateY(-0.3em); }
  100% {
    -webkit-transform: translateY(0.3em);
            transform: translateY(0.3em); } }

@-webkit-keyframes shake-animation {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  1.78571% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px); }
  3.57143% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  5.35714% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px); }
  7.14286% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  8.92857% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px); }
  10.71429% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@keyframes shake-animation {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  1.78571% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px); }
  3.57143% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  5.35714% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px); }
  7.14286% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  8.92857% {
    -webkit-transform: translate(0, 5px);
            transform: translate(0, 5px); }
  10.71429% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); } }

@media only screen and (max-width: 480px) {
  #background {
    background: #f8f8f8; }
    #background .layout-background {
      opacity: 1;
      background: transparent; } }

.intro {
  text-align: center; }
  .intro .heading {
    margin-bottom: 40px; }
    .intro .heading h2 {
      margin: 0;
      padding: 0; }

.about {
  font-size: 4rem;
  width: 100%;
  text-align: center;
  margin-bottom: 4rem; }

.background {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0.1;
  left: 50%;
  width: 80%; }
  .background img {
    width: 100%; }
  .background.small {
    width: 60%; }

.background-wrapper {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  padding: 55px 8%;
  font-family: "LexendDeca";
  /*mobile*/ }
  @media only screen and (max-width: 1920px) {
    .background-wrapper {
      padding: 40px 5%; } }
  @media only screen and (max-width: 1024px) {
    .background-wrapper {
      align-items: center; } }
  @media only screen and (max-width: 480px) {
    .background-wrapper {
      width: 100%;
      padding-top: 40px;
      line-height: 20px; } }
  @media only screen and (max-width: 1024px) {
    .background-wrapper h1 {
      width: 65%;
      font-size: 7rem;
      line-height: 4rem;
      margin-top: 80px; } }
  @media only screen and (max-width: 480px) {
    .background-wrapper h1 {
      text-align: -webkit-left;
      width: inherit;
      font-size: 8rem;
      line-height: 4rem; } }
  @media only screen and (max-width: 375px) {
    .background-wrapper h1 {
      margin-top: 35px;
      font-size: 5.6rem;
      line-height: 3rem; } }
  @media only screen and (max-width: 320px) {
    .background-wrapper h1 {
      margin-top: 30px;
      font-size: 4.5rem;
      line-height: 5rem; } }
  .background-wrapper h1 div {
    line-height: normal;
    line-height: initial;
    overflow: hidden;
    margin-bottom: -1.5rem; }
    @media only screen and (max-width: 480px) {
      .background-wrapper h1 div {
        margin-bottom: -1rem; } }
  .background-wrapper .content {
    width: 40%;
    font-size: 2rem;
    margin-bottom: 5rem;
    text-align: left;
    font-family: "Gilroy";
    font-weight: 700;
    color: #101c2f; }
    @media only screen and (max-width: 1700px) {
      .background-wrapper .content {
        font-size: 1.8rem; } }
    @media only screen and (max-width: 1024px) {
      .background-wrapper .content {
        width: 65%; } }
    .background-wrapper .content.wide {
      width: 100%; }
      @media only screen and (max-width: 1024px) {
        .background-wrapper .content.wide {
          width: 65%; } }
      @media only screen and (max-width: 480px) {
        .background-wrapper .content.wide {
          width: 100%; } }
    .background-wrapper .content:last-child {
      margin-bottom: 0px; }
    @media only screen and (max-width: 480px) {
      .background-wrapper .content {
        width: 100%;
        font-size: 2rem;
        line-height: 3rem;
        text-align: -webkit-left;
        color: #101c2f;
        margin-bottom: 1.8rem; } }
    @media only screen and (max-width: 320px) {
      .background-wrapper .content {
        font-size: 1.2rem;
        line-height: 2rem; } }

.skills {
  text-align: center;
  margin-top: 50px;
  /*mobile*/ }
  @media only screen and (max-width: 1024px) {
    .skills {
      margin-top: 55px; } }
  @media only screen and (max-width: 768px) {
    .skills {
      margin-top: 40px; } }
  @media only screen and (max-width: 480px) {
    .skills {
      margin: 30px 0px; } }
  .skills ul {
    list-style: none;
    display: inline;
    margin: 0;
    padding: 0; }
    @media only screen and (max-width: 1024px) {
      .skills ul {
        margin-left: 0;
        padding-left: 0; } }
    .skills ul li {
      display: inline-block;
      margin: 30px 1%;
      width: 20%; }
      @media only screen and (max-width: 1224px) {
        .skills ul li {
          margin: 20px 1%;
          width: 40%; } }
      @media only screen and (max-width: 480px) {
        .skills ul li {
          margin: 20px 1%; } }
      @media only screen and (max-width: 768px) {
        .skills ul li img {
          width: 80px;
          height: 80px; } }
      @media only screen and (max-width: 480px) {
        .skills ul li img {
          width: 60px;
          height: 60px; } }

.background-bg {
  position: absolute;
  height: 80%;
  left: -30%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  opacity: 0.5; }

.fp-viewing-outsystems section.fixed {
  background: #101c2f; }

#outsystems .content {
  color: white;
  font-family: "Gilroy";
  font-weight: 700;
  letter-spacing: 0.02rem;
  font-size: 3rem;
  text-align: end; }
  @media only screen and (max-width: 1700px) {
    #outsystems .content {
      width: 50%; } }
  @media only screen and (max-width: 1024px) {
    #outsystems .content {
      text-align: left; } }
  @media only screen and (max-width: 768px) {
    #outsystems .content {
      width: 65%; } }
  @media only screen and (max-width: 480px) {
    #outsystems .content {
      width: 100%;
      font-size: 2rem;
      line-height: 2.5rem; } }
  @media only screen and (max-width: 320px) {
    #outsystems .content {
      font-size: 1.2rem;
      line-height: 2rem; } }

.outsystems-logo {
  width: 500px; }
  @media only screen and (max-width: 1224px) {
    .outsystems-logo {
      width: 370px; } }
  @media only screen and (max-width: 768px) {
    .outsystems-logo {
      width: 340px; } }
  @media only screen and (max-width: 480px) {
    .outsystems-logo {
      width: 230px;
      margin-bottom: 15px; } }

.seperator-small {
  border-bottom: 2px solid white;
  margin: 30px 48%; }
  .seperator-small.m-50 {
    margin-top: 50px;
    margin-bottom: 50px; }
  @media only screen and (min-width: 1224px) {
    .seperator-small.d-hidden {
      display: none; } }
  @media only screen and (max-width: 1224px) {
    .seperator-small.t-hidden {
      display: none; } }

.tag {
  padding: 15px 25px;
  font-size: 1.2rem;
  margin: 0px 25px 25px 0px;
  width: 150px;
  display: inline-block;
  text-align: center;
  border-radius: 50px;
  text-transform: uppercase; }
  @media only screen and (max-width: 1224px) {
    .tag {
      width: 140px; } }
  @media only screen and (max-width: 768px) {
    .tag {
      padding: 10px 20px;
      margin: 0px 20px 20px 0px;
      width: 100px; } }
  @media only screen and (max-width: 480px) {
    .tag {
      padding: 5px 15px;
      width: 130px;
      margin: 10px 0; } }
  .tag .tag-text {
    margin-left: 15px; }
  .tag img {
    width: 25px;
    margin-right: 10px; }

.tag-wrapper {
  margin-top: 40px; }

.reach-out {
  margin-top: 80px;
  text-align: center; }
  .reach-out a {
    font-size: 2.5em; }

.os-logo-outer-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  overflow: hidden; }

.os-logo-inner-circle {
  width: 35%;
  height: 35%;
  border-radius: 50%;
  border: 2px solid white; }

.os-banner-wrapper {
  width: 86vh;
  height: 86vh;
  position: absolute;
  bottom: 50%;
  opacity: 0.3;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%); }

.os-banner-wrapper-2 {
  width: 150vh;
  height: 150vh;
  position: absolute;
  bottom: 50%;
  opacity: 0.3;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%); }

.bg-phrase-wrapper {
  font-size: 10em;
  left: 0px;
  bottom: 0px;
  position: absolute;
  color: #2c2c2c;
  font-weight: 700;
  line-height: 0.95em;
  height: 100%;
  width: 100%;
  font-family: "Gilroy";
  text-transform: uppercase; }
  @media only screen and (max-width: 1024px) {
    .bg-phrase-wrapper {
      font-size: 8em;
      opacity: 0.5; } }
  @media only screen and (max-width: 480px) {
    .bg-phrase-wrapper {
      font-size: 4em; } }
  .bg-phrase-wrapper .bg-phrase {
    position: absolute;
    top: 28%;
    right: 20%;
    color: #ffffff00;
    -webkit-text-stroke: white;
    -webkit-text-stroke-width: 0.15rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .bg-phrase-wrapper .bg-phrase:nth-child(2) {
      right: 60%;
      top: 45%; }
    .bg-phrase-wrapper .bg-phrase:nth-child(3) {
      right: 40%;
      top: 80%; }
    .bg-phrase-wrapper .bg-phrase:nth-child(4) {
      right: 10%;
      top: 63%; }
    .bg-phrase-wrapper .bg-phrase:nth-child(5) {
      right: 3%;
      top: 10%; }
    .bg-phrase-wrapper .bg-phrase:nth-child(6) {
      right: 50%;
      top: 13%; }
  .bg-phrase-wrapper.right {
    color: #ffffff;
    text-align: right;
    z-index: 9; }
    @media only screen and (max-width: 1024px) {
      .bg-phrase-wrapper.right {
        bottom: 35%; } }

#articles {
  position: relative; }
  @media only screen and (max-width: 480px) {
    #articles {
      background: #f8f8f8; }
      #articles .layout-background {
        opacity: 1;
        background: transparent; } }
  #articles .content {
    color: #101c2f;
    font-family: "Gilroy";
    font-weight: 700;
    letter-spacing: 0.02rem;
    font-size: 3rem;
    margin-bottom: 0px;
    line-height: normal;
    line-height: initial;
    overflow: hidden;
    margin-bottom: -1.5rem; }
    @media only screen and (max-width: 1224px) {
      #articles .content {
        font-size: medium;
        font-size: initial;
        margin-top: 40px; } }
    @media only screen and (max-width: 1024px) {
      #articles .content {
        text-align: center; } }
    @media only screen and (max-width: 480px) {
      #articles .content {
        font-size: 1.5rem;
        width: 80%;
        display: inline-block; } }
    @media only screen and (max-width: 320px) {
      #articles .content {
        font-size: 1.2rem;
        line-height: 2rem; } }
  #articles .title {
    font-size: 11em;
    line-height: 9rem;
    font-family: "Gilroy";
    text-transform: uppercase;
    font-weight: 900;
    color: #101c2f;
    margin-bottom: 25px;
    line-height: initial;
    overflow: hidden;
    margin-bottom: -1.5rem; }
    @media only screen and (max-width: 1224px) {
      #articles .title {
        font-size: 6em;
        line-height: 5.5rem; } }

.article-section-background {
  position: absolute;
  right: -192px;
  top: -190px;
  height: 180vh;
  width: 50vw;
  background: #464647;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  display: none; }

.articles-wrapper {
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 4%;
  box-sizing: border-box;
  overflow: hidden; }
  @media only screen and (max-width: 1024px) {
    .articles-wrapper {
      justify-content: flex-start;
      text-align: center;
      padding: 4%;
      align-content: center;
      padding-top: 360px; } }
  @media only screen and (max-width: 480px) {
    .articles-wrapper {
      padding-top: 120px; } }

.article-section-heading {
  width: 100%;
  margin-bottom: 10%; }
  @media only screen and (max-width: 1024px) {
    .article-section-heading {
      margin-bottom: 80px; } }

.article-section-body {
  width: 1000px;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1); }
  @media only screen and (max-width: 1920px) {
    .article-section-body {
      width: 880px; } }
  @media only screen and (max-width: 1700px) {
    .article-section-body {
      width: 750px; } }
  @media only screen and (max-width: 1024px) {
    .article-section-body {
      width: 720px;
      align-self: center; } }
  @media only screen and (max-width: 768px) {
    .article-section-body {
      width: 400px; } }

.article-list {
  width: 100%;
  display: inline-block; }

.article-card {
  height: 400px;
  width: 450px;
  background: white;
  display: inline-block;
  cursor: pointer;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 15px;
  -webkit-transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden; }
  .article-card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05); }
  @media only screen and (max-width: 1920px) {
    .article-card {
      height: 350px;
      width: 400px; } }
  @media only screen and (max-width: 1700px) {
    .article-card {
      height: 300px;
      width: 350px; } }
  @media only screen and (max-width: 1024px) {
    .article-card {
      height: 280px;
      width: 330px;
      margin-left: 0px; } }

.article-image {
  height: 70%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  position: relative; }

.article-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: -webkit-gradient(linear, left bottom, left top, from(black), color-stop(30%, rgba(255, 255, 255, 0)), color-stop(60%, rgba(255, 255, 255, 0)), color-stop(120%, black));
  background: -webkit-linear-gradient(bottom, black 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 60%, black 120%);
  background: linear-gradient(0deg, black 0%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 60%, black 120%); }

.article-text {
  display: flex;
  color: #0f1a29;
  padding: 25px;
  font-size: 2em;
  text-align: left;
  font-weight: 600;
  font-family: "SourceSansPro";
  position: relative;
  height: 30%;
  box-sizing: border-box;
  align-items: center; }
  @media only screen and (max-width: 1920px) {
    .article-text {
      font-size: 1.8em; } }
  @media only screen and (max-width: 1700px) {
    .article-text {
      font-size: 1.5em; } }
  @media only screen and (max-width: 1024px) {
    .article-text {
      font-size: 1.35em; } }

.article-hashtag-container {
  position: absolute;
  bottom: 15px;
  left: 25px;
  z-index: 2; }

.article-hashtag {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 1.4em;
  font-weight: 600;
  font-family: "SourceSansPro";
  border-radius: 20px;
  display: inline-block;
  margin-right: 10px;
  color: #ff5851; }
  .article-hashtag:nth-child(2n) {
    color: #bb860f; }
  .article-hashtag:nth-child(3n) {
    color: #606060; }
  @media only screen and (max-width: 1024px) {
    .article-hashtag {
      font-size: 1.2em; } }

.article-source-container {
  z-index: 3;
  position: absolute;
  right: 25px;
  top: 19px; }
  .article-source-container img {
    width: 30px; }
    @media only screen and (max-width: 1024px) {
      .article-source-container img {
        width: 20px; } }

.article-year {
  position: absolute;
  position: absolute;
  font-size: 3.8em;
  opacity: 0.1;
  color: #606060;
  right: 25px;
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  font-weight: 700;
  font-family: "Gilroy"; }
  @media only screen and (max-width: 1700px) {
    .article-year {
      font-size: 3em; } }

.article-outsystems-container {
  z-index: 3;
  position: absolute;
  left: 25px;
  top: 25px; }
  @media only screen and (max-width: 1024px) {
    .article-outsystems-container {
      top: 20px; } }
  .article-outsystems-container img {
    height: 25px; }
    @media only screen and (max-width: 1024px) {
      .article-outsystems-container img {
        height: 20px; } }

.slick-list {
  overflow: visible; }

.slick-dots {
  bottom: -55px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: left; }
  @media only screen and (max-width: 1024px) {
    .slick-dots {
      text-align: center; } }
  .slick-dots li.slick-active button:before {
    color: #101c2f; }
  .slick-dots li button:before {
    font-size: 16px; }

.fp-viewing-portfolio section.fixed,
.fp-viewing-sca section.fixed,
.fp-viewing-rrba section.fixed {
  background: #f8f8f8; }

.fp-viewing-portfolio .layout-background,
.fp-viewing-sca .layout-background,
.fp-viewing-rrba .layout-background {
  background: transparent; }

.project-icons {
  display: flex;
  list-style: none;
  margin-top: 0px;
  opacity: 0.8;
  padding: 0px;
  /* pop-up text */ }
  @media only screen and (max-width: 1224px) {
    .project-icons {
      display: inline-flex;
      text-align: center;
      position: inherit; } }
  @media only screen and (max-width: 1224px) {
    .project-icons {
      margin-bottom: 10px; } }
  .project-icons li {
    position: relative; }
    .project-icons li:hover span {
      visibility: visible;
      opacity: 1; }
    .project-icons li span {
      top: 50px;
      color: #666;
      position: absolute;
      bottom: 0;
      left: -25px;
      padding: 7px 12px;
      z-index: -1;
      font-size: 1rem;
      border-radius: 2px;
      background: #fff;
      visibility: hidden;
      opacity: 0;
      -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      height: 20px;
      width: auto;
      cursor: pointer;
      /* pop-up text arrow */ }
      .project-icons li span:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        position: absolute;
        top: -5px;
        left: 40px; }
    .project-icons li img {
      width: 30px;
      margin-right: 13px;
      /*tablet*/ }

@media only screen and (max-width: 1224px) and (orientation: landscape) {
  .project-icons li img {
    width: 25px; } }
      @media only screen and (max-width: 768px) {
        .project-icons li img {
          width: 25px; } }
    .project-icons li:last-child img {
      margin-right: 0px; }

picture .project-frame {
  position: absolute;
  left: 0;
  top: -15%;
  height: 120%;
  z-index: 3; }
  @media only screen and (max-width: 1700px) {
    picture .project-frame {
      height: 100%; } }
  @media only screen and (max-width: 1224px) {
    picture .project-frame {
      height: 85vh; } }
  @media only screen and (max-width: 480px) {
    picture .project-frame {
      height: 550px; } }

picture:nth-child(2) .project-frame {
  left: 50%;
  top: auto;
  bottom: -28%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 2; }
  @media only screen and (max-width: 1224px) {
    picture:nth-child(2) .project-frame {
      z-index: 3;
      bottom: auto; } }

picture:nth-child(3) .project-frame {
  left: auto;
  right: 0;
  top: -15%;
  z-index: 1; }

.project-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%; }
  @media only screen and (max-width: 1224px) {
    .project-wrapper {
      flex-direction: column !important;
      margin-bottom: 50px; } }

.project-content {
  text-align: left;
  position: relative; }
  @media only screen and (max-width: 1224px) {
    .project-content {
      width: 100%;
      text-align: center; } }

.project-name {
  font-size: 11em;
  line-height: 9rem;
  font-family: "Gilroy";
  text-transform: uppercase;
  font-weight: 900;
  color: #ff5851; }
  @media only screen and (max-width: 1224px) {
    .project-name {
      margin-bottom: 15px; } }
  @media only screen and (max-width: 1224px) {
    .project-name {
      font-size: 7em;
      line-height: 6rem; } }
  @media only screen and (max-width: 768px) {
    .project-name {
      font-size: 8em;
      line-height: 7rem; } }
  @media only screen and (max-width: 480px) {
    .project-name {
      font-size: 6em;
      line-height: 5rem; } }

.project-description {
  margin: 35px 0px;
  color: #606060;
  font-weight: 600;
  font-family: "SourceSansPro";
  margin-top: 0; }
  @media only screen and (max-width: 1224px) {
    .project-description {
      width: 100%;
      margin: 25px 0px; } }
  @media only screen and (max-width: 768px) {
    .project-description {
      margin: 15px 0px; } }
  @media only screen and (max-width: 480px) {
    .project-description p {
      font-size: 1.5rem; } }

.project-technologies {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 2.2rem;
  margin-top: 20px;
  margin-bottom: 18px;
  color: black; }
  @media only screen and (max-width: 1224px) {
    .project-technologies {
      margin-top: 0px;
      margin-bottom: 0px; } }
  @media only screen and (max-width: 320px) {
    .project-technologies {
      display: none; } }

.project-devices {
  margin: 30px 0px;
  color: black;
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 2.2rem;
  position: relative; }
  @media only screen and (max-width: 1224px) {
    .project-devices {
      margin: 10px 0px 15px 0px; } }
  @media only screen and (max-width: 1224px) {
    .project-devices {
      margin: 0px;
      font-size: 1.5rem; } }

.project-number {
  position: absolute;
  font-size: 7rem;
  opacity: 0.1;
  color: #606060;
  left: 0%;
  bottom: 50%;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  font-weight: 700;
  font-family: "Gilroy"; }
  @media only screen and (max-width: 1224px) {
    .project-number {
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content; } }

.project-link button {
  margin-top: 0; }

.fp-viewing-contact section.fixed {
  background: #101c2f; }

#contact .layout-wrapper {
  flex-direction: column;
  justify-content: center;
  align-items: center; }

#contact .bg-text {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 30rem;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #ffffff00;
  opacity: 0.1;
  -webkit-text-stroke: white;
  -webkit-text-stroke-width: 0.2rem;
  z-index: 0; }
  @media only screen and (max-width: 1700px) {
    #contact .bg-text {
      font-size: 20rem; } }
  @media only screen and (max-width: 768px) {
    #contact .bg-text {
      font-size: 10rem; } }
  @media only screen and (max-width: 480px) {
    #contact .bg-text {
      line-height: 13rem;
      font-size: 15rem; } }

#contact .contact-text {
  z-index: 1; }
  #contact .contact-text h1 {
    color: #ff5851;
    font-weight: 600;
    text-align: center;
    color: white;
    line-height: 8rem; }
    @media only screen and (max-width: 768px) {
      #contact .contact-text h1 {
        line-height: 4rem; } }
    @media only screen and (max-width: 480px) {
      #contact .contact-text h1 {
        font-size: 4rem;
        line-height: 5rem; } }
    #contact .contact-text h1.secondary {
      color: #ff5851; }

#contact .contact-links {
  margin-top: 3rem; }
  #contact .contact-links a {
    color: white;
    fill: white;
    font-size: 2.2rem; }
    #contact .contact-links a svg {
      height: 30px;
      width: 30px; }

#contact .back-to-top {
  position: absolute;
  bottom: 100px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }
  @media only screen and (max-width: 480px) {
    #contact .back-to-top {
      bottom: 50px; } }

@media only screen and (max-width: 320px) {
  #contact .link {
    font-size: 1.5rem;
    line-height: 3rem; } }

.text-top {
  position: absolute;
  color: #292929f7;
  text-transform: uppercase;
  font-size: 32rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  line-height: 15rem;
  font-weight: 700; }
  @media only screen and (max-width: 1700px) {
    .text-top {
      font-size: 23rem; } }
  @media only screen and (max-width: 1224px) {
    .text-top {
      font-size: 19rem; } }
  @media only screen and (max-width: 480px) {
    .text-top {
      font-size: 8rem;
      line-height: 6rem; } }

.scroll-back-up {
  margin-top: 50px;
  z-index: 9; }
  .scroll-back-up img {
    width: 35px; }

.outsystems-badge-wrapper {
  display: flex; }
  .outsystems-badge-wrapper.block-view {
    display: block; }
    @media only screen and (max-width: 320px) {
      .outsystems-badge-wrapper.block-view {
        display: none; } }
    .outsystems-badge-wrapper.block-view .badge-item {
      width: 20%; }
      @media only screen and (max-width: 1024px) {
        .outsystems-badge-wrapper.block-view .badge-item {
          width: 40%; } }
  @media only screen and (max-width: 1024px) {
    .outsystems-badge-wrapper {
      flex-flow: wrap; } }
  @media only screen and (max-width: 480px) {
    .outsystems-badge-wrapper {
      display: flex;
      margin-left: auto;
      margin-right: auto;
      top: auto;
      justify-content: space-around;
      margin-top: 20px; }
      .outsystems-badge-wrapper.mobile-layout .badge-group {
        justify-content: left !important;
        flex-flow: row;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        display: inline-flex; }
      .outsystems-badge-wrapper.mobile-layout .badge-name {
        display: none !important; }
      .outsystems-badge-wrapper.mobile-layout .badge-item {
        width: -webkit-max-content !important;
        width: -moz-max-content !important;
        width: max-content !important;
        margin-right: 10px !important;
        margin-left: 0px !important; } }
  @media only screen and (max-width: 480px) and (max-height: 670px) {
    .outsystems-badge-wrapper {
      bottom: 300px; } }
  @media only screen and (max-width: 375px) {
    .outsystems-badge-wrapper {
      bottom: 280px; } }
  @media only screen and (max-width: 375px) and (max-height: 670px) {
    .outsystems-badge-wrapper {
      bottom: 260px; } }
  .outsystems-badge-wrapper .badge-group {
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-flow: wrap; }

.badge-item {
  cursor: pointer;
  margin-right: 25px;
  text-align: left;
  margin-bottom: 20px; }
  @media only screen and (max-width: 480px) {
    .badge-item {
      text-align: center;
      display: block; } }
  @media only screen and (max-width: 375px) {
    .badge-item {
      margin-right: 10px;
      margin-bottom: 10px; } }
  .badge-item:last-child {
    margin-right: 0px; }
    @media only screen and (max-width: 480px) {
      .badge-item:last-child {
        margin-top: 0px;
        margin-left: 30px; } }
    @media only screen and (max-width: 375px) {
      .badge-item:last-child {
        margin-left: 10px; } }
  .badge-item .badge-name {
    -webkit-transition: 0.8s;
    transition: 0.8s;
    z-index: 2;
    position: relative;
    font-weight: 200;
    text-transform: uppercase;
    color: white;
    font-family: "Gilroy";
    font-size: 1.5rem;
    white-space: nowrap; }
    .badge-item .badge-name.toggle {
      width: 0px;
      overflow: hidden; }
      @media only screen and (max-width: 1024px) {
        .badge-item .badge-name.toggle {
          width: auto; } }
    @media only screen and (max-width: 1700px) {
      .badge-item .badge-name {
        font-size: 1.2rem; } }
    @media only screen and (max-width: 480px) {
      .badge-item .badge-name {
        font-weight: 600; } }
    @media only screen and (max-width: 375px) {
      .badge-item .badge-name {
        font-size: 1.4rem; } }
    @media only screen and (max-width: 320px) {
      .badge-item .badge-name {
        font-size: 1rem; } }
    .badge-item .badge-name .badge-os-logo {
      display: block;
      margin-bottom: -5px; }
      .badge-item .badge-name .badge-os-logo img {
        width: 100px; }
        @media only screen and (max-width: 1700px) {
          .badge-item .badge-name .badge-os-logo img {
            width: 80px; } }
        @media only screen and (max-width: 480px) {
          .badge-item .badge-name .badge-os-logo img {
            width: 60px; } }
  .badge-item.dark .badge-name {
    color: #101c2f;
    font-weight: 700; }
  .badge-item:hover .badge-name.toggle {
    width: 100%;
    overflow: visible; }
  .badge-item .badge-icon {
    width: 45px;
    margin-right: 15px; }
    @media only screen and (max-width: 1700px) {
      .badge-item .badge-icon {
        width: 40px; } }
    @media only screen and (max-width: 740px) {
      .badge-item .badge-icon {
        margin-right: 5px; } }
    @media only screen and (max-width: 375px) {
      .badge-item .badge-icon {
        width: 30px; } }

.awwwards-banner {
  position: fixed;
  z-index: 999;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  top: 50%;
  right: 0; }

.gsap-hidden {
  opacity: 0; }

.gsap-reveal {
  -webkit-transform: translateY(100%);
          transform: translateY(100%); }

.gsap-reveal-reverse {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%); }

.hide-overflow {
  overflow: hidden; }

#background .badge-group .gsap-hidden {
  opacity: 1; }

