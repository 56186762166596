#banner {
  .name {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 5;
    left: 50%;
    text-align: center;

    @media only screen and (max-width: $mobile-landscape-max) {
      top: 65%;
      display: flex;
      flex-direction: column-reverse;
    }

    @media only screen and (max-width: $mobile-portrait-min) {
      width: 80%;
    }

    p {
      font-size: 8rem;
      margin: 0;
      line-height: 8.5rem;

      text-shadow: 5px 2px 0px $dark-blue;
      font-size: 15rem;
      line-height: 12.5rem;
      font-family: "Bebas Neue";
      font-weight: 200;
      letter-spacing: 0.04em;

      @media only screen and (max-width: $desktop-large) {
        font-size: 12rem;
        line-height: 10rem;
      }

      @media only screen and (max-width: $mobile-landscape-max) {
        font-size: 9rem;
        line-height: 7rem;
      }

      @media only screen and (max-width: $mobile-portrait-min) {
        font-size: 6rem;
        line-height: 5rem;
      }
    }

    /*mobile*/
    @media only screen and (max-width: $mobile-landscape-max) {
      text-align: center;
      margin-left: auto;
      margin-right: auto;

      @media only screen and (max-height: $mobile-height-small) {
        top: 65%;
      }
    }
  }
}

.banner-awards {
  position: absolute;
  right: 40px;
  top: 100px;
  z-index: 5;

  li {
    list-style: none;
    margin-bottom: 15px;

    img {
      width: 100px;
    }
  }
}

.banner-contact-wrapper {
  display: none;
  @media only screen and (max-width: $mobile-landscape-max) {
    display: block;
    margin-top: 20px;
  }
  .contact-links ul li a {
    font-size: 3rem;

    svg {
      height: 25px;
      width: 25px;
    }
  }
}

// .mobile-banner-bg {
//   display: none;
//   @media only screen and (max-width: $mobile-landscape-max) {
//     display: block;
//     width: 100%;
//     height: 50%;
//     background: white;
//     z-index: 1;
//     position: absolute;
//     bottom: 0;

//     .name {
//       top: 50% !important;
//     }
//   }
// }
.scroll {
  position: absolute;
  left: 50%;
  z-index: 9999;
  transition: $transition;
  cursor: pointer;
  @media only screen and (max-width: $mobile-landscape-max) {
    display: none;
  }

  &.scroll-down {
    bottom: calc(#{$frame-size-large}/ 2);
    transform: translate(-50%, 50%);

    @media only screen and (max-width: $desktop-large) {
      bottom: calc(#{$frame-size-medium}/ 2);
    }
  }

  &.scroll-up {
    top: -10%;
    transform: translate(-50%, -50%);
    opacity: 0;

    @media only screen and (max-width: $desktop-large) {
      top: -10%;
    }
  }

  &.move-left {
    left: 25%;
  }

  img {
    width: 30px;
    @media only screen and (max-width: $desktop-large) {
      width: 22px;
    }
  }
}

.fp-viewing-contact {
  .scroll-down {
    opacity: 0;
    bottom: -10%;
    top: auto;
  }
  .scroll-up {
    top: calc(#{$frame-size-large}/ 2);

    @media only screen and (max-width: $desktop-large) {
      top: calc(#{$frame-size-medium}/ 2);
    }
    opacity: 1;
  }
}

.fp-viewing-contact {
  .scroll-text {
    left: -55px;
  }
  .up {
    display: initial;
  }

  .down {
    display: none;
  }
}
.typed-cursor {
  display: contents !important;
}

.download {
  position: absolute;
  top: $standard-padding;
  right: $standard-padding;
  z-index: 9;

  //mobile//
  @media only screen and (max-width: $mobile-landscape-max) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 30px;
  }

  img {
    width: 6rem;
  }
}

//Animations//

@keyframes pulse {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}

@keyframes bob {
  0% {
    transform: translateY(0.3em);
  }
  50% {
    transform: translateY(-0.3em);
  }
  100% {
    transform: translateY(0.3em);
  }
}

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }
  1.78571% {
    transform: translate(0, 5px);
  }
  3.57143% {
    transform: translate(0, 0);
  }
  5.35714% {
    transform: translate(0, 5px);
  }
  7.14286% {
    transform: translate(0, 0);
  }
  8.92857% {
    transform: translate(0, 5px);
  }
  10.71429% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
