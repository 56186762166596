header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  box-sizing: border-box;
  align-items: center;
  padding: 0px $frame-size-large;
  height: $frame-size-large;
  transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);

  @media only screen and (max-width: $desktop-large) {
    height: $frame-size-medium;
    padding: 0px $frame-size-medium;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    padding: 0px $frame-size-small;
  }

  .name {
    text-decoration: none;
    font-weight: 600;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    color: $dark-blue;
    font-family: "LexendDeca";
    transition: $transition;
    transition-delay: 0.2s;
    @media only screen and (max-width: $desktop-large) {
      font-size: 1rem;
    }
    @media only screen and (max-width: $tablet-large) {
      width: 35%;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      font-size: 1.4rem;
      width: 50%;
    }
  }

  .contact-links {
    @media only screen and (max-width: $mobile-landscape-max) {
      display: none;
    }
  }
}
@media only screen and (max-width: $mobile-landscape-max) {
  .fp-viewing-background {
    header {
      .name {
        color: $red;
      }
    }
    .sharer a {
      border-color: $red;
      color: $red;
    }
  }
  .fp-viewing-outsystems,
  .fp-viewing-contact {
    .name {
      color: white;
    }
    .sharer a {
      border-color: white;
      color: white;
    }
  }
}

.header-options {
  display: flex;
  height: 22px;
  overflow: hidden;
}

.contact-links {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 110px;

  @media only screen and (max-height: $mobile-height-small) {
    bottom: 80px;
  }

  ul {
    list-style: none;
    margin: 0;
    display: inline;
    padding: 0;

    li {
      //opacity: 0.5;
      margin: 0 15px;
      display: inline;

      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        margin-right: 0px;
      }

      a {
        transition: 0.2s;
        color: $dark-blue;
        font-size: 1.2rem;
        display: inline-flex;
        &:hover,
        &.active {
          color: $red;
        }

        svg {
          transition: 0.2s;
          width: 12px;
          height: 20px;
          &:hover {
            fill: $red;
            color: $red;
          }
        }
      }
    }
  }

  img {
    max-width: 1.2rem;
    max-height: 1.2rem;
  }
}
