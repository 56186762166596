html {
  scroll-behavior: smooth;
  font-size: $root-size-desktop;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  text-rendering: optimizeLegibility;
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;

  &.no-scroll {
    overflow: hidden;
  }

  /*
  @media only screen and (max-width: $tablet-large) {
    font-size: $root-size-tablet-large;
  }
  @media only screen and (max-width: $tablet-small) {
    font-size: $root-size-tablet-small;
  }
  
  @media only screen and (max-width: $mobile-landscape-max) {
    font-size: $root-size-mobile;
  }

  @media only screen and (max-width: $mobile-portrait-max) {
    font-size: $root-size-mobile-small;
  }
*/
}

body {
  font-family: "SourceSansPro";
  height: 100%;
  color: white;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /*reset for mobile browsers */
  font-weight: normal;
  font-family: "Gilroy";
}

h1 {
  text-transform: uppercase;
  font-family: "Gilroy";
  font-size: 10rem;
  font-weight: 900;
  line-height: 9rem;
  margin-bottom: 5rem;
  color: $dark-blue;
  margin-top: 0px;

  @media only screen and (max-width: $desktop-large) {
    line-height: 5rem;
    margin-bottom: 3rem;
  }

  @media only screen and (max-width: $tablet-small) {
    font-size: 5.5rem;
    line-height: 5rem;
    margin-bottom: 3rem;
  }

  &.white {
    color: white;
  }
}

h2 {
  font-size: 4rem;
}

h3 {
  font-size: 3rem;
}

h4 {
  font-weight: 600;
}

h5 {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: bolder;
}

p {
  font-size: 2rem;
}

section {
  background: transparent;
  min-height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  right: 0;
  transition: $transition;

  &.infinite {
    padding: 0px;
    /*tablet*/
    @media only screen and (max-width: $tablet-large) {
      padding: 7.5rem;
    }

    /*mobile*/
    @media only screen and (max-width: $mobile-landscape-max) {
      padding: 0;
    }
  }

  &.fixed {
    position: fixed;
  }

  .floating-text {
    position: absolute;
    font-size: 3rem !important;
    color: grey;

    &:nth-child(1) {
      top: 40%;
      right: 50%;
    }
    &:nth-child(2) {
      top: 20%;
      right: 10%;
    }
    &:nth-child(3) {
      top: 60%;
      right: 60%;
    }
    &:nth-child(4) {
      top: 10%;
      right: 90%;
    }
  }
}

.container {
  position: relative;
  width: 1440px;
  max-width: calc(100% - 48px);
  margin-left: auto;
  margin-right: auto;

  &.wide {
    width: 1840px;

    @media only screen and (max-width: $desktop) {
      width: 75%;
    }
  }

  @media only screen and (max-width: $desktop) {
    width: 75%;
  }
}

strong {
  font-weight: bold;
  text-shadow: 5px 4px 0px #1b1b1b;
}

.link {
  text-align: center;
  color: $red;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.6s;
  font-size: 2.2rem;
  width: fit-content;
  z-index: 1;
  cursor: pointer;
  border-bottom: 1px solid;
  border-top: 1px solid;
  padding-bottom: 3px;
  font-weight: 500;
  font-family: "SourceSansPro";

  &:hover {
    color: $modern-black;
    background: $red;
  }

  &.large-link {
    font-size: 3rem;
  }
}
.button,
button {
  font-family: "LexendDeca";
  outline: none;
  border: 2px solid;
  border-color: $red;
  border-radius: 40px;
  background-color: $red;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
  padding: 20px 70px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s;
  text-decoration: none;
  display: inline-block;
  width: fit-content;
  &.secondary {
    background-color: transparent;
    color: $red;
  }

  @media only screen and (max-width: $desktop-extra-large) {
    padding: 15px 50px;
  }

  @media only screen and (max-width: $tablet-large) {
    padding: 15px 50px;
    @media only screen and (orientation: landscape) {
      padding: 15px 50px;
    }
  }

  @media only screen and (max-width: $tablet-small) {
    padding: 12px 40px;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    font-size: 1.3rem;
  }

  &:hover {
    background-color: transparent;
    color: $red;

    &.secondary {
      background-color: $red;
      color: white;
    }
  }
}

.code {
  font-family: SourceCodePro;
}

.subtitle {
  font-size: 2rem;

  @media only screen and (max-width: $mobile-landscape-max) {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  @media only screen and (max-width: $mobile-portrait-min) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.white-bg {
  background: white;
  color: $modern-black;
}
.grey-bg {
  background: $modern-grey;
  color: $modern-black;

  a.link {
    color: $modern-black;
  }
}

.white-text {
  color: white;
}

.hide {
  display: none;
  &.show-on-mobile {
    @media only screen and (max-width: $mobile-landscape-max) {
      display: block;
    }
  }
}

.hide-on-mobile {
  @media only screen and (max-width: $mobile-landscape-max) {
    display: none;
  }
}

.center-align {
  text-align: center;
}

.bring-front {
  z-index: 1;
}

.switch-wrapper {
  position: relative;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.vertical-align {
  display: flex;
  align-items: center;
}

.col-2 {
  display: flex;
  width: 100%;
  margin-bottom: 50px;

  @media only screen and (max-width: $mobile-landscape-max) {
    display: block;
  }
}

.col {
  width: 50%;
  display: inline-block;

  @media only screen and (max-width: $mobile-landscape-max) {
    width: 100%;

    &.align-right {
      text-align: center;
    }

    &.align-left {
      text-align: center;
    }
  }
}

.margin-top-50 {
  margin-top: 50px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-top-0 {
  margin-top: 0;
}
@media only screen and (max-width: $desktop) {
  .t-margin-top-30 {
    margin-top: 30px;
  }

  .t-margin-top-60 {
    margin-top: 60px;
  }
}

@media only screen and (max-width: $mobile-landscape-max) {
  .m-margin-top-0 {
    margin-top: 0px;
  }
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

@media only screen and (max-width: $desktop) {
  .t-margin-bottom-0 {
    margin-bottom: 0px;
  }
}

@media only screen and (max-width: $mobile-landscape-max) {
  .m-margin-bottom-10 {
    margin-bottom: 10px;
  }
}

.os-logo {
  border: 55vh solid #862e20;
  border-radius: 100vh;
  width: 40vh;
  height: 40vh;
  position: absolute;
  bottom: 50%;
  opacity: 0.8;
  left: 75%;
  transform: translate(-50%, 50%);
  background-image: radial-gradient(transparent 37%, #1a1a1a 98%);
}
