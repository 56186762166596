.fp-viewing-portfolio,
.fp-viewing-sca,
.fp-viewing-rrba {
  section.fixed {
    background: #f8f8f8;
  }
  .layout-background {
    background: transparent;
  }
}

.project-icons {
  display: flex;
  //justify-content: flex-end;
  list-style: none;
  margin-top: 0px;
  opacity: 0.8;
  padding: 0px;
  //align-items: flex-end;

  @media only screen and (max-width: $desktop) {
    display: inline-flex;
    text-align: center;
    position: inherit;
  }

  @media only screen and (max-width: $desktop) {
    margin-bottom: 10px;
  }

  /* pop-up text */

  li {
    position: relative;
    &:hover span {
      visibility: visible;
      opacity: 1;
    }
    span {
      top: 50px;
      color: #666;
      position: absolute;
      bottom: 0;
      left: -25px;
      padding: 7px 12px;
      z-index: -1;
      font-size: 1rem;
      border-radius: 2px;
      background: #fff;
      visibility: hidden;
      opacity: 0;
      -o-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -webkit-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      -moz-transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      transition: all 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
      height: 20px;
      width: auto;
      cursor: pointer;

      /* pop-up text arrow */

      &:before {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
        position: absolute;
        top: -5px;
        left: 40px;
      }
    }

    img {
      width: 30px;
      margin-right: 13px;
      /*tablet*/
      @media only screen and (max-width: $desktop) {
        @media only screen and (orientation: landscape) {
          width: 25px;
        }
      }
      @media only screen and (max-width: $tablet-small) {
        width: 25px;
      }
    }

    &:last-child {
      img {
        margin-right: 0px;
      }
    }
  }
}
picture {
  .project-frame {
    position: absolute;
    left: 0;
    top: -15%;
    height: 120%;
    z-index: 3;

    @media only screen and (max-width: $desktop-large) {
      height: 100%;
    }

    @media only screen and (max-width: $desktop) {
      height: 85vh;
    }

    @media only screen and (max-width: $mobile-landscape-max) {
      height: 550px;
      //width: 65vw;
    }
  }

  &:nth-child(2) .project-frame {
    left: 50%;
    top: auto;
    bottom: -28%;
    transform: translateX(-50%);
    z-index: 2;
    @media only screen and (max-width: $desktop) {
      z-index: 3;
      bottom: auto;
    }
  }

  &:nth-child(3) .project-frame {
    left: auto;
    right: 0;
    top: -15%;
    z-index: 1;
  }
}

.project-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  @media only screen and (max-width: $desktop) {
    flex-direction: column !important;
    margin-bottom: 50px;
  }
}

.project-content {
  text-align: left;
  position: relative;

  @media only screen and (max-width: $desktop) {
    width: 100%;
    text-align: center;
  }
}

.project-name {
  font-size: 11em;
  line-height: 9rem;
  font-family: "Gilroy";
  text-transform: uppercase;
  font-weight: 900;
  color: $red;

  @media only screen and (max-width: $desktop) {
    margin-bottom: 15px;
  }

  @media only screen and (max-width: $desktop) {
    font-size: 7em;
    line-height: 6rem;
    @media only screen and (orientation: landscape) {
    }
  }
  @media only screen and (max-width: $tablet-small) {
    font-size: 8em;
    line-height: 7rem;
  }
  @media only screen and (max-width: $mobile-landscape-max) {
    font-size: 6em;
    line-height: 5rem;
  }
}

.project-description {
  margin: 35px 0px;
  color: $grey;
  font-weight: 600;
  font-family: "SourceSansPro";
  margin-top: 0;

  @media only screen and (max-width: $desktop) {
    width: 100%;
    margin: 25px 0px;
  }

  @media only screen and (max-width: $tablet-small) {
    margin: 15px 0px;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    p {
      font-size: 1.5rem;
    }
  }
}

.project-technologies {
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 2.2rem;
  margin-top: 20px;
  margin-bottom: 18px;
  color: black;

  @media only screen and (max-width: $desktop) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @media only screen and (max-width: $mobile-portrait-min) {
    display: none;
  }
}

.project-devices {
  margin: 30px 0px;
  color: black;
  font-family: "Gilroy";
  font-weight: 700;
  font-size: 2.2rem;
  position: relative;

  @media only screen and (max-width: $desktop) {
    margin: 10px 0px 15px 0px;
  }

  @media only screen and (max-width: $desktop) {
    margin: 0px;
    font-size: 1.5rem;
  }
}

.project-number {
  position: absolute;
  font-size: 7rem;
  opacity: 0.1;
  color: $grey;
  left: 0%;
  bottom: 50%;
  transform: translateY(50%);
  font-weight: 700;
  font-family: "Gilroy";

  @media only screen and (max-width: $desktop) {
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
  }
}

.project-link {
  button {
    margin-top: 0;
  }
}
