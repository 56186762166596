form {
  text-align: right;
  input,
  textarea {
    font-family: "LexendDeca";
    border: 0;
    outline: none;
    border-bottom: 2px solid #9fa2a4;
    width: 100%;
    height: 50px;
    margin-bottom: 40px;
    font-size: 1.5rem;
    transition: 0.3s;
    resize: none;
    text-transform: uppercase;

    @media only screen and (max-width: $tablet-large) {
      margin-bottom: 20px;
    }

    @media only screen and (max-width: $mobile-portrait-max) {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }

    &:focus {
      border-color: $red;
      &::placeholder {
        text-transform: uppercase;
        opacity: 0;
      }
    }
  }
  textarea {
    height: auto;
    &::placeholder {
      line-height: 100px;

      @media only screen and (max-width: $mobile-landscape-max) {
        line-height: 60px;
      }
    }
  }

  .error-message {
    color: $red;
    width: 100%;
    text-align: left;
    margin-top: -20px;
    font-family: "Gilroy";
  }

  .success-message {
    width: 80%;
    text-align: left;
    color: $red !important;
    font-weight: 900 !important;
    font-family: "Gilroy";
  }
}

.project-form {
  width: 100%;

  .w100-right {
    margin-top: 60px;
    text-align: right;

    @media only screen and (max-width: $desktop-extra-large) {
      margin-top: 20px;
    }
  }
  .form-line {
    border-bottom: 3px solid $yellow;
    width: 10%;
    display: inline-block;
  }
  form {
    width: 100%;
    margin-top: 30px;

    @media only screen and (max-width: $desktop-extra-large) {
      margin-top: 10px;
    }
  }
}
