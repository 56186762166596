.layout-wrapper {
  display: flex;
  height: 100%;
  padding: $frame-size-large;
  box-sizing: border-box;

  @media only screen and (max-width: $desktop-large) {
    padding: $frame-size-medium;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    padding: 0px;
  }
}

.layout-base {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  overflow: hidden;
}

.layout-left {
  width: 27%;
  padding-left: 13%;
  height: 75%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: $desktop) {
    width: 70%;
    padding-left: 0;
    text-align: center;
  }
  @media only screen and (max-width: $tablet-small) {
    height: 60%;
    margin-top: 40px;
  }

  @media only screen and (max-width: $mobile-portrait-max) {
    margin-top: 17vh;
  }
}

.layout-right {
  width: 63.5%;
  height: 100%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: $desktop) {
    width: 108%;
    height: 55%;
  }
  @media screen and (orientation: landscape) {
    width: 70%;
  }
}

.layout-heading {
  font-size: 7rem;
  font-weight: 600;
}

.layout-subheading {
  font-size: 2.5rem;
  font-weight: 100;
}

.layout-floating-card {
  width: 100%;
  height: 100%;
  //background: $modern-black;
  //overflow: hidden;
  position: relative;
  text-align: right;

  @media only screen and (max-width: $mobile-landscape-max) {
    margin-top: 10vh;
  }

  @media only screen and (max-width: $mobile-portrait-max) {
    margin-top: 20vh;
  }

  .slide-1 {
    height: 100%;
    //width: 5%;
    background: $red;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
  }

  .slide-effect {
    animation: card-grow 0.8s ease-in-out 0.4s;
    -webkit-animation: card-grow 0.8s ease-in-out 0.4s;
    -moz-animation: card-grow 0.8s ease-in-out 0.4s;
    -o-animation: card-grow 0.8s ease-in-out 0.4s;
    -ms-animation: card-grow 0.8s ease-in-out 0.4s;
  }
}

.layout-background {
  height: 100%;
  width: 100%;
  background: #0e18217a;
  position: absolute;
  overflow: hidden;
  opacity: 0.6;
  top: 0;
  left: 0;
  transition: $transition;
}

.project-separator {
  height: 7px;
  background: #b72609;
  width: 60px;
  margin-bottom: 40px;
  margin-top: 20px;
  border-radius: 20px;
}

@-webkit-keyframes card-grow {
  from {
    width: 0%;
    transform: translateX(-100%);
  }
  to {
    width: 100%;
    transform: translateX(100%);
  }
}
@-moz-keyframes card-grow {
  from {
    width: 0%;
    transform: translateX(-100%);
  }
  to {
    width: 100%;
    transform: translateX(100%);
  }
}
@-ms-keyframes card-grow {
  from {
    width: 0%;
    transform: translateX(-100%);
  }
  to {
    width: 100%;
    transform: translateX(100%);
  }
}
@-o-keyframes card-grow {
  from {
    width: 0%;
    transform: translateX(-100%);
  }
  to {
    width: 100%;
    transform: translateX(100%);
  }
}
@keyframes card-grow {
  from {
    width: 0%;
    transform: translateX(-100%);
  }
  to {
    width: 100%;
    transform: translateX(100%);
  }
}
