@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");

@font-face {
  font-family: "LexendDeca";
  src: url("../fonts/LexendDeca.ttf");
  font-display: swap;
}

@font-face {
  font-family: "SourceCodePro";
  src: url("../fonts/SourceCodePro-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-ExtraLight.ttf");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-ExtraLightItalic.ttf");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-Light.ttf");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-Italic.ttf");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-SemiBold.ttf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-Bold.ttf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-Black.ttf");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SourceSansPro";
  src: url("../fonts/SourceSansPro-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Light.woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Regular.woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Medium.woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Bold.woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("../fonts/Gilroy-Heavy.woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

.strong-font {
  font-weight: 900;
}

.red-font {
  color: $red;
  font-family: "LexendDeca";
}

.yellow-font {
  color: $yellow;
  font-family: "LexendDeca";
}

.turquoise-font {
  color: $turquoise-dark;
  font-family: "LexendDeca";
}

.white-font {
  color: white;
  font-family: "LexendDeca";
}
