nav {
  position: fixed;
  left: 20px;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;

  @media only screen and (max-width: $desktop-large) {
    left: 10px;
  }

  @media only screen and (max-width: $mobile-landscape-max) {
    display: none;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px;

    li {
      margin: 12px 0px;
      opacity: 0.4;
      transition: 0.2s;
      cursor: pointer;
      background: $dark-blue;
      height: 4px;
      width: 20px;
      color: #0e1821;
      position: relative;
      text-align: center;
      font-size: 2rem;
      overflow: hidden;

      @media only screen and (max-width: $desktop-large) {
        font-size: 1.8rem;
      }

      &:hover {
        height: 6px;
        opacity: 1;
      }

      &:first-child {
        margin-top: 0px;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      &.active {
        opacity: 1;
        height: 5px;
        overflow: visible;
        margin-bottom: 30px;
      }

      span {
        font-family: "Gilroy";
        font-weight: 700;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 6px;
      }

      a {
        overflow: hidden;
        color: white;
      }
    }
  }
}
