//Device Sizes
$mobile-portrait-min: 320px;
$mobile-portrait-max: 375px;
$mobile-landscape-max: 480px;
$mobile-height-small: 670px;
$mobile-height-large: 740px;
$tablet-small: 768px;
$tablet-large: 1024px;
$desktop: 1224px;
$desktop-large: 1700px;
$desktop-extra-large: 1920px;

$root-size-desktop: 14px;
$root-size-tablet-large: 12px;
$root-size-tablet-small: 11px;
$root-size-mobile: 9px;
$root-size-mobile-small: 7px;

$frame-size-large: 55px;
$frame-size-medium: 40px;
$frame-size-small: 20px;

$dark-blue: #101c2f;
$yellow: #bb860f;
$red: #ff5851;
$grey: #606060;
$turquoise: #2dd2b9;
$turquoise-dark: #0d8976;
$off-white: #f8f8f8;

$modern-black: #1a1a1a;
$modern-grey: #e6e5e5;
$modern-blue: #1b61c8;

$standard-padding: 16.5rem;
$standard-padding-tablet: 80px;
$standard-padding-mobile: 60px;

$transition: 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
$transition-time: 0.7s;
